import React from 'react'
import zoho_banner from '../assets/img/ZohhoBanner.png'
import zoho_crm from '../assets/img/zoho_crm.png'
import zoho_creator from '../assets/img/zoho_creator.png'
import zoho_inventory from '../assets/img/zoho_inventory.png'
import LowImplementationRisk from '../assets/img/LowImplementationRisk.png'
import LowImplementationTime from '../assets/img/LowImplementationTime.png'
import HighFlexibility from '../assets/img/HighFlexibility.png'
import HighEffeciency from '../assets/img/HighEffeciency.png'
import HighSecurity from '../assets/img/HighSecurity.png'
import OurClients from '../components/OurClients'
import OurExperts from '../components/OurExperts'
import uparrow from '../assets/img/arrow-right-up.png'
import MobilappPortfolio_lkn from '../assets/img/MobilappPortfolio_lkn.png'
import MobilappPortfolio_bee from '../assets/img/MobilappPortfolio_bee.png'
import MobilappPortfolio_accopedia from '../assets/img/MobilappPortfolio_accopedia.png'
import MobilappPortfolio_class from '../assets/img/MobilappPortfolio_class.png'
import uparrowWhite from '../assets/img/arrow-right-up_white.png'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import shadowRight from '../assets/img/shadowRight.png'
import { Link } from 'react-router-dom'



export default function ZohoImplementation() {
    const { t: tzoho, i18n: i18nzoho } = useTranslation('zoho');
    const { t: tcommon, i18n: i18ncommon } = useTranslation('common');


    return (
        <>
            {/* banner section */}
            <div className='container'>
                <div className='text-center'>
                    <button className='btn main_text rounded-pill ps-4 pe-4 text-uppercase' style={{ backgroundColor: '#FFE6D2' }}>{tzoho("banner").sub_heading}</button>
                </div>
                <div className='row align-items-center' style={{ height: '80vh' }}>
                    <div className='col-12 py-4 col-md d-flex align-items-center justify-content-center'>
                        <div className='center'>

                            <h1>{tzoho("banner").heading}</h1>
                            <p className='fs-5'> {tzoho("banner").heading_content}
                            </p>
                            <Link  to="/contact" style={{ backgroundColor: '#FD7E14' }} className='btn btn-md me-3 text-white rounded-1 p-2 pe-4 ps-4'>{tcommon("get_in_touch")} <FontAwesomeIcon icon={faArrowRightLong} className="ps-2" /></Link>
                        </div>
                    </div>
                    <div className='col-12 py-4 col-md d-flex align-items-center justify-content-center'>
                        <img src={zoho_banner} className='' style={{ width: '60%', float: 'right' }} />
                    </div>
                </div>
            </div>

            {/*  */}
            <div className='container py-5 pb-0 text-center'>
                <h3 className='fw-bold pb-2'>{tzoho("cards_section").heading}</h3>
                <p className=''>{tzoho("cards_section").heading_content}</p>
                <div className='container py-5 pt-2'>
                    <div className='row'>
                        <div className='col'>
                            <div className='border border-2 mb-3 rounded-3' style={{ height: '100%' }}>
                                <div class="card p-4 pt-4 pb-4 border-0 mt-0" >
                                    <img class=" mx-auto" style={{ width: '40%' }} src={zoho_crm} alt="Card image cap" />
                                    <div class="card-body text-center">
                                        <h4 class="card-title fw-bolder main_text pb-2">{tzoho("cards_section").zogo_crm_title}</h4>
                                        <p class="text-dark ">{tzoho("cards_section").zogo_crm_content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='border border-2 mb-3 rounded-3' style={{ height: '100%' }}>
                                <div class="card p-4 pt-4 pb-4 border-0 mt-0">
                                    <img class=" mx-auto" style={{ width: '40%' }} src={zoho_creator} alt="Card image cap" />
                                    <div class="card-body text-center">
                                        <h4 class="card-title fw-bolder main_text pb-2">{tzoho("cards_section").zoho_inventory_title}</h4>
                                        <p class="text-dark">{tzoho("cards_section").zoho_inventory_content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='border border-2 mb-3 rounded-3' style={{ height: '100%' }}>
                                <div class="card p-4 pt-4 pb-4 border-0 mt-0">
                                    <img class=" mx-auto" style={{ width: '40%' }} src={zoho_inventory} alt="Card image cap" />
                                    <div class="card-body text-center">
                                        <h4 class="card-title fw-bolder main_text pb-2">{tzoho("cards_section").zoho_creator_title}</h4>
                                        <p class="text-dark">{tzoho("cards_section").zoho_creator_content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* development stages section */}
            <div className='container-fluid bg-dark'>
                <div className='container py-5 my-3'>
                    <h3 className='text-white text-center pb-3 py-3'>{tzoho("developement_stage_section").heading}</h3 >
                    <div className='row'>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={LowImplementationRisk} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tzoho("developement_stage_section").low_financial_risk_title}</h4>
                                    <p class="card-text">{tzoho("developement_stage_section").low_financial_risk_content}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={LowImplementationRisk} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tzoho("developement_stage_section").low_implementation_risk_title}</h4>
                                    <p class="card-text">{tzoho("developement_stage_section").low_implementation_risk_content}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={LowImplementationTime} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tzoho("developement_stage_section").low_implementation_time_title}</h4>
                                    <p class="card-text">{tzoho("developement_stage_section").low_implementation_time_content}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={HighFlexibility} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tzoho("developement_stage_section").high_flexibility_title}</h4>
                                    <p class="card-text">{tzoho("developement_stage_section").high_flexibility_content}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={HighEffeciency} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tzoho("developement_stage_section").high_efficiency_title}</h4>
                                    <p class="card-text">{tzoho("developement_stage_section").high_efficiency_content}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={HighSecurity} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tzoho("developement_stage_section").high_security_title}</h4>
                                    <p class="card-text">{tzoho("developement_stage_section").high_security_content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* client section */}
            <OurClients />


            {/* FAQ Section */}
            <div className='container-fluid py-5 position-relative mb-5'>
                <h3 className='text-center pb-3'>Frequently Asked Questions</h3>
                <div class="accordion  mx-auto faq" id="accordionExample" style={{ width: '75%' }}>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                What are your main technical specialties?
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                We design apps for both mobile devices and the web using the most recent and popular technology. Web: YII, Laravel, Codeignitor, AngularJS, NodeJS, PHP, WordPress, and Mobile: native for iPhone, native for Android, and React Native Wireframe and prototyping, UI design.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                What are the main focuses when creating mobile applications?
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                We are knowledgeable about both native and hybrid forms. A smartphone application created especially for a mobile operating system is known as a "native app" (think Objective-C or Swift for iOS vs. Java for Android). In essence, native apps are created by those standards since they are native to the user's OS. At their heart, hybrid applications are simply webpages wrapped in a native interface.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                How much would it cost to develop our mobile application?
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                The following are the primary elements that make up the total cost calculation:<br />

                                Designing, Coding, Development, Testing, Integrating an app with the current infrastructure.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Can we transfer the projects our clients have given us to Clapsoft in German?
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Yes, we can do such projects using the offshore delivery method. We have completed such projects for our esteemed clients and promise timely service and careful attention to our delivery timelines.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                How long will it take to construct our mobile app?
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                The time frame is provided depending on the complexity of the application. The average time to complete the development process for an application, from conception to launch, is about 6 to 7 months, while some technical apps can take up to a year or longer.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                Which platform needs to we aim for? Android, iOS, or both?
                            </button>
                        </h2>
                        <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                We advise launching the product on both platforms due to the increasing popularity of both iPhone and the most recent Android smartphones.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='position-absolute' style={{ right: 0, bottom: -250 }}>
                    <img src={shadowRight} width="100%" style={{ position: 'relative', zIndex: -1 }} />
                </div>
            </div>

            {/* portfolio section */}
            {/* <div className='container py-5'>
                <h1 className='text-center fw-bold pb-4'>{tzoho("portfolio_section").heading}</h1>
                <div className='row'>
                    <div className='col'>
                        <div class="card border-0 mb-3" style={{ height: '100%' }}>
                            <img src={MobilappPortfolio_lkn} class="card-img-top" alt="..." />
                            <div class="card-body  mt-4 rounded-3 p-4" style={{ backgroundColor: '#292929', color: 'white' }}>
                                <h5 class="card-title">Lakshmi krishna <span><img src={uparrowWhite} /></span></h5>
                                <p class="card-text">{tzoho("portfolio_section").lnk_content}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div class="card border-0 mb-3" style={{ height: '100%' }}>
                            <img src={MobilappPortfolio_bee} class="card-img-top" alt="..." />
                            <div class="card-body  mt-4 rounded-3 p-4" style={{ backgroundColor: '#CECDCB' }}>
                                <h5 class="card-title">Bee Group <span><img src={uparrow} /></span></h5>
                                <p class="card-text">{tzoho("portfolio_section").bee_content}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div class="card border-0 mb-3" style={{ height: '100%' }}>
                            <img src={MobilappPortfolio_accopedia} class="card-img-top" alt="..." />
                            <div class="card-body  mt-4 rounded-3 p-4" style={{ backgroundColor: '#F4F4F4' }}>
                                <h5 class="card-title">Accopedia <span><img src={uparrow} /></span></h5>
                                <p class="card-text">{tzoho("portfolio_section").accopedia_content}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div class="card border-0 mb-3" style={{ height: '100%' }}>
                            <img src={MobilappPortfolio_class} class="card-img-top" alt="..." />
                            <div class="card-body  mt-4 rounded-3 p-4" style={{ backgroundColor: '#292929', color: 'white' }}>
                                <h5 class="card-title">Class Tales <span><img src={uparrowWhite} /></span></h5>
                                <p class="card-text">{tzoho("portfolio_section").clastale_content}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* our expert */}
            <OurExperts />

        </>
    )
}
