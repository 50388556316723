import React from 'react'
import OurExperts from '../components/OurExperts'
import OurClients from '../components/OurClients'
import TestingBanner from '../assets/img/TestingBanner.png'
import kalatanStudio from '../assets/img/kalatanStudio.png'
import appium from '../assets/img/appium.png'
import postman from '../assets/img/postman.png'
import TestingServiceBanner from '../assets/img/TestingServiceBanner.png'
import manualTesting_icon from '../assets/img/manualTesting_icon.png'
import apiTesting_icon from '../assets/img/apiTesting_icon.png'
import dbTesting_icon from '../assets/img/dbTesting_icon.png'
import automationTesting_icon from '../assets/img/automationTesting_icon.png'
import appiumTesting_icon from '../assets/img/appiumTesting_icon.png'
import EcommerceBanner from '../assets/img/EcommerceBanner.png'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import shadowRight from '../assets/img/shadowRight.png'
import { Link } from 'react-router-dom'



export default function ServiceTesting() {
    const { t: tservice_testing, i18n: i18nservice_testing } = useTranslation('service_testing');
    const { t: tcommon, i18n: i18ncommon } = useTranslation('common');


    return (
        <>

            {/* banner section */}
            <div className='container'>
                <div className='text-center'>
                    <button className='btn main_text rounded-pill ps-4 pe-4 text-uppercase' style={{ backgroundColor: '#FFE6D2' }}>{tservice_testing("banner").sub_heading}</button>
                </div>
                <div className='row align-items-center' style={{ height: '80vh' }}>
                    <div className='col-12 col-md'>
                        <div className='center'>
                            <h1>{tservice_testing("banner").heading}</h1>
                            <p className='fs-5'> {tservice_testing("banner").heading_content}</p>
                            <Link to="/contact" style={{ backgroundColor: '#FD7E14' }} className='btn btn-md me-3 text-white rounded-1 p-2 pe-4 ps-4'>{tcommon("get_in_touch")} <FontAwesomeIcon icon={faArrowRightLong} className="ps-2" /></Link>
                        </div>
                    </div>
                    <div className='col-12 py-4 col-md '>
                        <img src={TestingBanner} className='allBannerImg' style={{ width: '70%', float: 'right' }} />
                    </div>
                </div>
            </div>

            <div className='container py-5 pb-0 text-center'>
                <h3 className='fw-bold pb-2'>{tservice_testing("cards_section").heading}</h3>
                <p className=''>{tservice_testing("cards_section").heading_content}</p>
                <div className='container py-5 pt-2'>
                    <div className='row'>
                        <div className='col'>
                            <div className='border border-2 mb-0 rounded-3' style={{ height: '100%' }}>
                                <div class="card p-4 pt-4 pb-4 border-0 mt-0">
                                    <img class=" mx-auto" style={{ width: '15%' }} src={kalatanStudio} alt="Card image cap" />
                                    <div class="card-body text-center">
                                        <h4 class="card-title fw-bolder main_text pb-2">{tservice_testing("cards_section").katalon_studio_title}</h4>
                                        <p class="text-dark ">{tservice_testing("cards_section").katalon_studio_content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='border border-2 mb-0 rounded-3' style={{ height: '100%' }}>
                                <div class="card p-4 pt-4 pb-4 border-0 mt-0">
                                    <img class=" mx-auto" style={{ width: '15%' }} src={appium} alt="Card image cap" />
                                    <div class="card-body text-center">
                                        <h4 class="card-title fw-bolder main_text pb-2">{tservice_testing("cards_section").appium_title}</h4>
                                        <p class="text-dark">{tservice_testing("cards_section").appium_content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='border border-2 mb-0 rounded-3' style={{ height: '100%' }}>
                                <div class="card p-4 pt-4 pb-4 border-0 mt-0">
                                    <img class=" mx-auto" style={{ width: '27%' }} src={postman} alt="Card image cap" />
                                    <div class="card-body text-center">
                                        <h4 class="card-title fw-bolder main_text pb-2">{tservice_testing("cards_section").postman_title}</h4>
                                        <p class="text-dark">{tservice_testing("cards_section").postman_content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* our clients */}
            <OurClients />

            <div className='container pt-5'>
                <h3 className='fw-bold pb-2 text-center'>{tservice_testing("tech_section").heading}</h3>
                <div className='row py-5'>
                    <div className='col-12 col-md-5'>
                        <img src={TestingServiceBanner} className='w-75' />
                    </div>
                    <div className='col-12 col-md-7 ps-5'>
                        <p className='pt-5 fs-6'>
                            {tservice_testing("tech_section").heading_content}
                        </p>
                        <div className='row pt-3'>
                            <div className='col-5'>
                                <p><span><img src={manualTesting_icon} alt='...' className='me-2' style={{ width: '10%' }} /></span><span className=''>{tservice_testing("tech_section").manual_testing}</span></p>
                            </div>
                            <div className='col-7'>
                                <p><span><img src={apiTesting_icon} alt='...' className='me-2' style={{ width: '8%' }} /></span><span className=''>{tservice_testing("tech_section").api_testing}</span></p>
                            </div>
                            <div className='col-5'>
                                <p><span><img src={dbTesting_icon} alt='...' className='me-2' style={{ width: '10%' }} /></span><span className=''>{tservice_testing("tech_section").db_testing}</span></p>
                            </div>
                            <div className='col-7'>
                                <p><span><img src={automationTesting_icon} alt='...' className='me-2' style={{ width: '8%' }} /></span><span className=''>{tservice_testing("tech_section").automation_testing}</span></p>
                            </div>
                            <div className='col-4'>
                                <p><span><img src={appiumTesting_icon} alt='...' className='me-2' style={{ width: '10%' }} /></span> <span className=''>
                                    {tservice_testing("tech_section").appium}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* FAQ Section */}
            <div className='container-fluid py-5 position-relative mb-5'>
                <h3 className='text-center pb-3'>Frequently Asked Questions</h3>
                <div class="accordion  mx-auto faq" id="accordionExample" style={{ width: '75%' }}>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                {tservice_testing("faq_section").question1}
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_testing("faq_section").answer1}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                {tservice_testing("faq_section").question2}
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_testing("faq_section").answer2}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                {tservice_testing("faq_section").question3}
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_testing("faq_section").answer3}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                {tservice_testing("faq_section").question4}
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_testing("faq_section").answer4}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                {tservice_testing("faq_section").question5}
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_testing("faq_section").answer5}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                {tservice_testing("faq_section").question6}
                            </button>
                        </h2>
                        <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_testing("faq_section").answer6}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='position-absolute' style={{ right: 0, bottom: -250 }}>
                    <img src={shadowRight} width="100%" style={{ position: 'relative', zIndex: -1 }} />
                </div>
            </div>

            {/* our expert */}
            <OurExperts />
        </>
    )
}
