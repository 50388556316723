import React, { useRef, useState } from 'react'
import '../assets/css/Contact.css'
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';


export default function Contact() {
  const { t: tcontact, i18n: i18ncontact } = useTranslation('contact');
  const form = useRef();
  const form1 = useRef();


  console.log('form', form)

  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    phone_number: "",
    subjects: "",
    news: ""
  });


  const [formData1, setFormData1] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    phone_number: "",
    message: "",
  });

  const handleChange = (e) => {
    e.preventDefault()
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    emailjs
      .sendForm('service_qcwsh9g', 'template_1kvk69f', form.current, 'qU_hLezn0dLXsqFBw')
      .then(
        () => {
          console.log('SUCCESS!');
          setShowAlert(true);
          setFormData({
            first_name: "",
            last_name: "",
            email: "",
            company_name: "",
            phone_number: "",
            subjects: "",
            news: ""
          });
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      )
      .finally(() => {
        setLoading(false); // Stop loading
      });
  };


  const handleChange1 = (e) => {
    e.preventDefault();
    setFormData1({ ...formData1, [e.target.name]: e.target.value });
  };

  const sendEmail1 = (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    emailjs
      .sendForm('service_qcwsh9g', 'template_vzqal81', form1.current, 'qU_hLezn0dLXsqFBw')
      .then(
        () => {
          console.log('SUCCESS!');
          setShowAlert(true);
          setFormData1({
            first_name: "",
            last_name: "",
            email: "",
            company_name: "",
            phone_number: "",
            message: ""
          });
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      )
      .finally(() => {
        setLoading(false); // Stop loading
      });
  };





  return (
    <>
      {/* banner section */}
      <div className='container text-center'>
        <button className='btn main_text rounded-pill ps-4 pe-4 mb-3 text-uppercase' style={{ backgroundColor: '#FFE6D2' }}>{tcontact("banner").sub_heading}</button>
        <h1 className='fw-bolder'>{tcontact("banner").heading}</h1>
        <p>{tcontact("banner").heading_content}</p>
      </div>

      <div className='container rounded-4 pb-5'>
        <div class="d-flex align-items-start row p-5 rounded-2" style={{ backgroundColor: '#F4F4F4' }}>
          <div className='col-12 col-md'>
            <div className=' mx-auto' style={{ width: '85%' }}>
              <h2>{tcontact("side_nav_section").nav_heading}</h2>

              <div class="nav flex-column contact_tab nav-pills me-3 w-75 py-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <button style={{ textAlign: 'left' }} class="nav-link fs-5 active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">{tcontact("side_nav_section").nav1}</button>
                <button style={{ textAlign: 'left' }} class="nav-link fs-5 " id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">{tcontact("side_nav_section").nav2}</button>
                <button style={{ textAlign: 'left' }} class="nav-link fs-5 " id="v-pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#v-pills-disabled" type="button" role="tab" aria-controls="v-pills-disabled" aria-selected="false" >{tcontact("side_nav_section").nav3}</button>
              </div>

              <div className='d-flex py-5 mt-5 justify-content-between'>
                <div>
                  <h3 className='main_text'>Visit Us</h3>
                  <p>
                    Pforzheimer Str. 26<br />

                    75196 Remchingen
                  </p>
                </div>
                <div>
                  <div>
                    <h3 className='main_text'>Contact</h3>
                    <p>
                      contact@company.com<br /> +49 72323090134
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content col-12 col-md" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabindex="0">
              <div className='bg-white rounded-4 p-4 '>
                <p>{tcontact("form1_section").heading}
                </p>
                {showAlert && (
                  <div className="alert alert-success" role="alert">
                    Details submitted successfully!
                  </div>
                )}
                <form ref={form1} onSubmit={sendEmail1}>
                  <div className='mb-3'>
                    <div className='row'>
                      <div className='col-12 col-md'>
                        <label>{tcontact("form1_section").first_name}<span className='text-danger'> *</span></label>
                        <input type='text' name='first_name' className='form-control rounded-3 p-2' value={formData1.first_name} placeholder="" required onChange={handleChange1} />
                      </div>
                      <div className='col-12 col-md'>
                        <label>{tcontact("form1_section").last_name}<span className='text-danger'> *</span></label>
                        <input type='text' name='last_name' className='form-control rounded-3 p-2' value={formData1.last_name} placeholder=""required onChange={handleChange1} />
                      </div>
                    </div>
                  </div>

                  <div className='mb-3'>
                    <div className='row'>
                      <div className='col-12 col-md'>
                        <label>{tcontact("form1_section").email}<span className='text-danger'> *</span></label>
                        <input type='text' name='email' className='form-control rounded-3 p-2' value={formData1.email} placeholder="" required onChange={handleChange1} />
                      </div>
                      <div className='col-12 col-md'>
                        <label>{tcontact("form1_section").company_name}<span className='text-danger'> *</span></label>
                        <input type='text' name='company_name' className='form-control rounded-3 p-2' value={formData1.company_name} placeholder="" required onChange={handleChange1} />
                      </div>
                    </div>
                  </div>

                  <div className='mb-3'>
                    <label>{tcontact("form1_section").phone_number}<span className='text-danger'> *</span></label>
                    <input type='text' name='phone_number' className='form-control rounded-3 p-2' value={formData1.phone_number} placeholder="" required onChange={handleChange1} />
                  </div>

                  <div className='mb-3'>
                    <label>{tcontact("form1_section").message}<span className='text-danger'> *</span></label>
                    <textarea name='message'  className='form-control rounded-3 p-2' value={formData1.message} placeholder="" required onChange={handleChange1} />
                  </div>

                  <div className='text-center'>
                    <button type="submit" className='btn rounded-2 p-5 pt-3 pb-3 mt-2 text-white' style={{ backgroundColor: '#FD7E14', whiteSpace: 'nowrap' }}>Send Messages</button>
                  </div>
                </form>
              </div>
            </div>
            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabindex="0">
              <div className='bg-white rounded-4 p-4 '>
                <p>{tcontact("form1_section").heading}
                </p>
                <form>
                  <div className='mb-3'>
                    <div className='row'>
                      <div className='col-12 col-md'>
                        <lable>{tcontact("form1_section").first_name}<span className='text-danger'> *</span></lable>
                        <input type='text' id='name' className='form-control rounded-3 p-2' value="" placeholder="" />

                      </div>
                      <div className='col-12 col-md'>
                        <lable>{tcontact("form1_section").last_name}<span className='text-danger'> *</span></lable>
                        <input type='text' id='name' className='form-control rounded-3 p-2' value="" placeholder="" />

                      </div>
                    </div>
                  </div>

                  <div className='mb-3'>
                    <div className='row'>
                      <div className='col-12 col-md'>
                        <lable>{tcontact("form1_section").position}<span className='text-danger'> *</span></lable>
                        <input type='text' id='name' className='form-control rounded-3' value="" placeholder="" />

                      </div>
                      <div className='col-12 col-md'>
                        <lable>{tcontact("form1_section").qualification}<span className='text-danger'> *</span></lable>
                        <input type='text' id='name' className='form-control rounded-3' value="" placeholder="" />

                      </div>
                    </div>
                  </div>


                  <div className='mb-3'>
                    <lable>{tcontact("form1_section").phone_number}<span className='text-danger'> *</span></lable>
                    <input type='email' id='email' className='form-control rounded-3' value="" placeholder="" />
                  </div>

                  <div className='mb-3'>
                    <div className='row'>
                      <div className='col-12 col-md'>
                        <lable><span>Erfahrung *</span></lable>
                        <input type='text' id='name' className='form-control rounded-3 p-2' value="" placeholder="" />

                      </div>
                      <div className='col-12 col-md'>
                        <lable style={{ visibility: 'hidden' }}>test</lable>
                        <input type='text' id='name' className='form-control rounded-3 p-2' value="" placeholder="" />

                      </div>
                    </div>
                  </div>

                  <div className='mb-3'>
                    <lable>{tcontact("form1_section").attach_your_resume}<span className='text-danger'> *</span></lable>
                    <input type='file' id='email' className='form-control rounded-3 p-2' value="" placeholder="" />
                  </div>
                  <div className='text-center'>
                    <button type="submit" className='btn rounded-2 p-5 pt-3 pb-3 mt-2 text-white' style={{ backgroundColor: '#FD7E14', whiteSpace: 'nowrap' }}>Send Messages</button>
                  </div>
                </form>
              </div>
            </div>
            <div class="tab-pane fade" id="v-pills-disabled" role="tabpanel" aria-labelledby="v-pills-disabled-tab" tabindex="0">
              <div className='bg-white rounded-4 p-4 '>
                <p>{tcontact("form1_section").heading}
                </p>
                {showAlert && (
                  <div className="alert alert-success" role="alert">
                    Details submitted successfully!
                  </div>
                )}
                <form ref={form} onSubmit={sendEmail}>
                  <div className='mb-3'>
                    <div className='row'>
                      <div className='col-12 col-md'>
                        <lable>{tcontact("form1_section").first_name}<span className='text-danger'> *</span></lable>
                        <input type='text' name='first_name' className='form-control rounded-3 p-2' value={formData.first_name} placeholder="" required onChange={handleChange} />

                      </div>
                      <div className='col-12 col-md'>
                        <lable>{tcontact("form1_section").last_name}<span className='text-danger'> *</span></lable>
                        <input type='text' name='last_name' className='form-control rounded-3 p-2' value={formData.last_name} placeholder="" required onChange={handleChange} />
                      </div>
                    </div>
                  </div>

                  <div className='mb-3'>
                    <div className='row'>
                      <div className='col-12 col-md'>
                        <lable>{tcontact("form1_section").email}<span className='text-danger'> *</span></lable>
                        <input type='text' name='email' className='form-control rounded-3 p-2' value={formData.email} placeholder="" required onChange={handleChange} />

                      </div>
                      <div className='col-12 col-md'>
                        <lable>{tcontact("form1_section").company_name}<span className='text-danger'> *</span></lable>
                        <input type='text' name='company_name' className='form-control rounded-3 p-2' value={formData.company_name} placeholder="" required onChange={handleChange} />
                      </div>
                    </div>
                  </div>


                  <div className='mb-3'>
                    <lable>{tcontact("form1_section").phone_number}<span className='text-danger'> *</span></lable>
                    <input type='text' name='phone_number' className='form-control rounded-3 p-2' value={formData.phone_number} placeholder="" required onChange={handleChange} />
                  </div>

                  <div className='mb-3'>
                    <lable>{tcontact("form1_section").subjects}<span className='text-danger'> *</span></lable>
                    <input type='text' name='subjects' className='form-control rounded-3 p-2' value={formData.subjects} placeholder="" required onChange={handleChange} />
                  </div>

                  <div className='mb-3'>
                    <lable>{tcontact("form1_section").news}<span className='text-danger'> *</span></lable>
                    <input type='text' name='news' className='form-control rounded-3 p-2' value={formData.news} placeholder="" required onChange={handleChange} />
                  </div>
                  <div className='text-center'>
                    <button type="submit" className='btn rounded-2 p-5 pt-3 pb-3 mt-2 text-white' style={{ backgroundColor: '#FD7E14', whiteSpace: 'nowrap' }}>
                      {/* {loading && (
                        <div class="spinner-border text-dark" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                      )} */}
                      Send Messages</button>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
