import React, { useEffect, useState } from 'react'
import managedITBanner from '../assets/img/managedITBanner.png'
import ManagedItTimeline from '../assets/img/ManagedItTimeline.png'
import ManagedItTimeline_en from '../assets/img/ManagedItTimeline_en.png'
import OurClients from '../components/OurClients'
import OurExperts from '../components/OurExperts'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import shadowRight from '../assets/img/shadowRight.png'
import { Link } from 'react-router-dom'


export default function ServiceManagedIT() {
    const [localStorageValue, setLocalStorageValue] = useState('');

    const { t, i18n } = useTranslation('header');
    const { t: tservice_managed_it, i18n: i18nservice_managed_it } = useTranslation('service_managed_it');
    const { t: tcommon, i18n: i18ncommon } = useTranslation('common');


    const currentLanguage = i18n.language;

    const value = localStorage.getItem('i18nextLng');

    return (
        <>
            {/* banner section */}
            <div className='container'>
                <div className='text-center'>
                    <button className='btn main_text rounded-pill ps-4 pe-4 text-uppercase' style={{ backgroundColor: '#FFE6D2' }}>{tservice_managed_it("banner").sub_heading}</button>
                </div>
                <div className='row align-items-center' style={{ height: '80vh' }}>
                    <div className='col-12 py-4 col-md'>
                        <div className='center'>

                            <h1>{tservice_managed_it("banner").heading}</h1>
                            <p className='fs-5'> {tservice_managed_it("banner").heading_content}</p>
                            <Link to="/contact" style={{ backgroundColor: '#FD7E14' }} className='btn btn-md me-3 text-white rounded-1 p-2 pe-4 ps-4'>{tcommon("get_in_touch")} <FontAwesomeIcon icon={faArrowRightLong} className="ps-2" /></Link>
                        </div>
                    </div>
                    <div className='col-12 py-4 col-md '>
                        <img src={managedITBanner} className='' style={{ width: '70%', float: 'right' }} />
                    </div>
                </div>
            </div>

            <div className='container py-5 text-center'>
                <h3 className='fw-bold pb-2'>{tservice_managed_it("cards_section").heading}</h3>
                <p className=''>{tservice_managed_it("cards_section").heading_content}</p>
                {
                    currentLanguage == 'de' ? (
                        <img src={ManagedItTimeline} alt='..' className='w-50' />
                    ) : (
                        <img src={ManagedItTimeline_en} alt='..' className='w-50' />
                    )
                }
            </div>

            <OurClients />

            {/* FAQ Section */}
            <div className='container-fluid py-5 position-relative mb-5'>
                <h3 className='text-center pb-3'>Frequently Asked Questions</h3>
                <div class="accordion  mx-auto faq" id="accordionExample" style={{ width: '75%' }}>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                {tservice_managed_it("faq_section").question1}
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_managed_it("faq_section").answer1}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                {tservice_managed_it("faq_section").question2}
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_managed_it("faq_section").answer2}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                {tservice_managed_it("faq_section").question3}
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_managed_it("faq_section").answer3}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                {tservice_managed_it("faq_section").question4}
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_managed_it("faq_section").answer4}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                {tservice_managed_it("faq_section").question5}
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_managed_it("faq_section").answer5}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                {tservice_managed_it("faq_section").question6}
                            </button>
                        </h2>
                        <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_managed_it("faq_section").answer6}
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className='position-absolute' style={{ right: 0, bottom: -250 }}>
                    <img src={shadowRight} width="100%" style={{ position: 'relative', zIndex: -1 }} />
                </div>
            </div>
            <OurExperts />

        </>
    )
}
