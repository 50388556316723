import React from 'react'
import ServicePrototypeBanner from '../assets/img/ServicePrototypeBanner.png'
import PrototypeBanner_en from '../assets/img/PrototypeBanner_en.png'
import PrototypeBanner_de from '../assets/img/PrototypeBanner_de.png'
import client1 from '../assets/img/client1.png'
import client2 from '../assets/img/client2.png'
import client3 from '../assets/img/client3.png'
import client4 from '../assets/img/client4.png'
import client5 from '../assets/img/client5.png'
import OurExperts from '../components/OurExperts'
import development_stages_recuremnent from '../assets/img/development_stages_recuremnent.png'
import development_stages_design from '../assets/img/development_stages_design.png'
import development_stages_development from '../assets/img/development_stages_development.png'
import development_stages_buildPrototype from '../assets/img/development_stages_buildPrototype.png'
import development_stages_implementPrototype from '../assets/img/development_stages_implementPrototype.png'
import development_stages_refinePrototype from '../assets/img/development_stages_refinePrototype.png'
import { useTranslation } from 'react-i18next'
import OurClients from '../components/OurClients'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'



export default function ServicePrototype() {

    const { t, i18n } = useTranslation('header');
    const { t: tservice_prototype, i18n: i18nservice_prototype } = useTranslation('service_prototype');
    const { t: tclients, i18n: i18ntclients } = useTranslation('clients');
    const { t: tcommon, i18n: i18ncommon } = useTranslation('common');

    const currentLanguage = i18n.language;

    return (
        <>
            {/* banner section */}
            <div className='container'>
                <div className='text-center'>
                    <button className='btn main_text rounded-pill ps-4 pe-4 text-uppercase' style={{ backgroundColor: '#FFE6D2' }}>{tservice_prototype("banner").sub_heading}</button>
                </div>
                <div className='row align-items-center' style={{ height: '80vh' }}>
                    <div className='col-12 col-md py-4'>
                        <div className='center'>

                            <h1>{tservice_prototype("banner").heading}</h1>
                            <p className='fs-5'> {tservice_prototype("banner").heading_content}</p>
                            <Link  to="/contact" style={{ backgroundColor: '#FD7E14' }} className='btn btn-md me-3 text-white rounded-1 p-2 pe-4 ps-4'>{tcommon("get_in_touch")} <FontAwesomeIcon icon={faArrowRightLong} className="ps-2" /></Link>
                        </div>
                    </div>
                    <div className='col-12 col-md py-4'>
                        <img src={ServicePrototypeBanner} className='' style={{ width: '90%', height: 'auto', float: 'right' }} />
                    </div>
                </div>
            </div>

            {/* app platform section */}
            <div className='py-5'>
                <h3 className='text-center pb-5 fw-bold'>{tservice_prototype("cards_section").heading}</h3>

                {
                    currentLanguage == 'de' ? (
                        <img src={PrototypeBanner_de} className='w-100' />

                    ) : (
                        <img src={PrototypeBanner_en} className='w-100' />
                    )
                }
            </div>

            {/* client section */}
            {/* <div className='container pb-3'>
                <div className='heading text-center pb-5'>
                    <p className='text-uppercase m-0 main_text fw-bold'>Auszug zufriedener</p>
                    <h1 className=' fw-bold'>Kunden und Partner</h1>
                </div>
                <div className='d-flex justify-content-evenly flex-wrap'>
                    <div><img src={client1} alt='...' className='' /></div>
                    <div><img src={client2} alt='...' className='' /></div>
                    <div><img src={client3} alt='...' className='' /></div>
                    <div><img src={client4} alt='...' className='' /></div>
                    <div><img src={client5} alt='...' className='' /></div>
                </div>
            </div> */}
            <div className='py-5'>
                <OurClients />
            </div>

            {/* development stages section */}
            <div className='container-fluid bg-dark'>
                <div className='container py-5 my-3'>
                    <h3 className='text-white text-center pb-3 py-3'>{tservice_prototype("developement_stage_section").heading}</h3 >
                    <div className='row'>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={development_stages_recuremnent} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tservice_prototype("developement_stage_section").requirement_title}</h4>
                                    <p class="card-text">{tservice_prototype("developement_stage_section").requirement_content}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={development_stages_design} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tservice_prototype("developement_stage_section").design_title}</h4>
                                    <p class="card-text">{tservice_prototype("developement_stage_section").design_content}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={development_stages_buildPrototype} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tservice_prototype("developement_stage_section").build_prototype_title}</h4>
                                    <p class="card-text">{tservice_prototype("developement_stage_section").build_prototype_content}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={development_stages_development} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tservice_prototype("developement_stage_section").initial_user_evaluation_title}</h4>
                                    <p class="card-text">{tservice_prototype("developement_stage_section").initial_user_evaluation_content}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={development_stages_refinePrototype} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tservice_prototype("developement_stage_section").refining_prototype_title}</h4>
                                    <p class="card-text">{tservice_prototype("developement_stage_section").refining_prototype_content}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={development_stages_implementPrototype} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tservice_prototype("developement_stage_section").implement_product_title}</h4>
                                    <p class="card-text">{tservice_prototype("developement_stage_section").implement_product_content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* our expert section */}

            <div className='py-5 pb-2'>
                <OurExperts />
            </div>
        </>
    )
}
