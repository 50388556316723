import React from 'react'
import processBanner from '../assets/img/processBanner.png'
import SCHRITT1 from '../assets/img/SCHRITT1.png'
import SCHRITT2 from '../assets/img/SCHRITT2.png'
import SCHRITT3 from '../assets/img/SCHRITT3.png'
import SCHRITT4 from '../assets/img/SCHRITT4.png'
import SCHRITT5 from '../assets/img/SCHRITT5.png'
import OurClients from '../components/OurClients'
import OurExperts from '../components/OurExperts'
import ProcessTimeline from '../components/ProcessTimeline'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'



export default function Process() {

    const { t: tcommon, i18n: i18ncommon } = useTranslation('common');
    const { t: tprocess, i18n: i18nprocess } = useTranslation('process');


    return (
        <>
            {/* banner section */}
            <div className='container'>
                <div className='text-center'>
                    <button className='btn main_text rounded-pill ps-4 pe-4' style={{ backgroundColor: '#FFE6D2' }}>{tprocess("banner").sub_text}</button>
                </div>
                <div className='row align-items-center' style={{ height: '80vh' }}>
                    <div className='col-12 py-4 col-md-5 d-flex align-items-center'>
                        <div className='center'>

                            <h1>{tprocess("banner").heading}</h1>
                            <p className='fs-5'> {tprocess("banner").heading_content}</p>
                            <Link to="/contact" style={{ backgroundColor: '#FD7E14' }} className='btn btn-md me-3 text-white rounded-1 p-2 pe-4 ps-4'>{tcommon("get_in_touch")} <FontAwesomeIcon icon={faArrowRightLong} className="ps-2" />
                            </Link>
                        </div>
                    </div>
                    <div className='col-12 py-4 col-md'>
                        <img src={processBanner} className='' style={{ width: '65%', float: 'right' }} />
                    </div>
                </div>
            </div>



            <ProcessTimeline />

            {/* FAQ Section */}
            <div className='container-fluid py-5 position-relative mb-5'>
                <h3 className='text-center pb-3'>Frequently Asked Questions</h3>
                <div class="accordion  mx-auto faq" id="accordionExample" style={{ width: '75%' }}>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                {tprocess("faq_section").question1}
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tprocess("faq_section").answer1}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                {tprocess("faq_section").question2}
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tprocess("faq_section").answer2}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                {tprocess("faq_section").question3}
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tprocess("faq_section").answer3}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                {tprocess("faq_section").question4}
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tprocess("faq_section").answer4}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                {tprocess("faq_section").question5}
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tprocess("faq_section").answer5}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                {tprocess("faq_section").question7}
                            </button>
                        </h2>
                        <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tprocess("faq_section").answer7}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='position-absolute' style={{ right: 0, bottom: -150 }}>
                        <img src={Shadow} width="65%" style={{ position: 'relative', zIndex: -1 }} />
                    </div> */}
            </div>
            {/* client section */}
            <OurClients />

            {/* our expert */}
            <OurExperts />
        </>
    )
}
