import React from 'react'
import '../assets/css/ServiceMobile.css'
import ServiceMobileBanner from '../assets/img/ServiceMobileBanner.png'
import android from '../assets/img/androidIcon.png'
import ios from '../assets/img/iosIcon.png'
import settings from '../assets/img/settings.png'
import client1 from '../assets/img/client1.png'
import client2 from '../assets/img/client2.png'
import client3 from '../assets/img/client3.png'
import client4 from '../assets/img/client4.png'
import client5 from '../assets/img/client5.png'
import MobilappPortfolio_lkn from '../assets/img/MobilappPortfolio_lkn.png'
import MobilappPortfolio_bee from '../assets/img/MobilappPortfolio_bee.png'
import MobilappPortfolio_accopedia from '../assets/img/MobilappPortfolio_accopedia.png'
import MobilappPortfolio_class from '../assets/img/MobilappPortfolio_class.png'
import uparrow from '../assets/img/arrow-right-up.png'
import uparrowWhite from '../assets/img/arrow-right-up_white.png'
import development_stages_recuremnent from '../assets/img/development_stages_recuremnent.png'
import development_stages_planning from '../assets/img/development_stages_planning.png'
import development_stages_design from '../assets/img/development_stages_design.png'
import development_stages_development from '../assets/img/development_stages_development.png'
import development_stages_testing from '../assets/img/development_stages_testing.png'
import development_stages_deployement from '../assets/img/development_stages_deployement.png'
import OurExperts from '../components/OurExperts'
import { useTranslation } from 'react-i18next'
import Shadow from '../assets/img/shadow.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import OurClients from '../components/OurClients'
import { Link } from 'react-router-dom'


export default function ServiceMobile() {

    const { t: tservice_mobile, i18n: i18nservice_mobile } = useTranslation('service_mobile');
    const { t: tclients, i18n: i18ntclients } = useTranslation('clients');
    const { t: tcommon, i18n: i18ncommon } = useTranslation('common');


    return (
        <>
            {/* banner section */}
            <div className='container'>
                <div className='text-center'>
                    <button className='btn main_text rounded-pill ps-4 pe-4 text-uppercase' style={{ backgroundColor: '#FFE6D2' }}>{tservice_mobile("banner").sub_heading}</button>
                </div>
                <div className='row align-items-center' style={{ height: '80vh' }}>
                    <div className='col-12 col-md'>
                        <div className='center'>
                            <h1>{tservice_mobile("banner").heading}</h1>
                            <p className='fs-5'> {tservice_mobile("banner").heading_content}</p>
                            <Link  to="/contact" style={{ backgroundColor: '#FD7E14' }} className='btn btn-md me-3 text-white rounded-1 p-2 pe-4 ps-4'>{tcommon("get_in_touch")} <FontAwesomeIcon icon={faArrowRightLong} className="ps-2" /></Link>
                        </div>
                    </div>
                    <div className='col-12 py-4 col-md '>
                        <img src={ServiceMobileBanner} className='allBannerImg' style={{ width: '70%', float: 'right' }} />
                    </div>
                </div>
            </div>

            {/* app platform section */}
            <div className='container py-5'>
                <h3 className='text-center pb-4 fw-bold'>{tservice_mobile("cards_section").heading}</h3>
                <div className='row'>
                    <div className='col'>
                        <div className='border border-2 mb-3 rounded-3' style={{ height: '100%' }}>
                            <div class="card p-4 pt-5 pb-4 border-0 mt-4" >
                                <img class=" mx-auto" style={{ width: '15%' }} src={android} alt="Card image cap" />
                                <div class="card-body text-center">
                                    <h4 class="card-title fw-bolder main_text pb-2">{tservice_mobile("cards_section").android_development_title}</h4>
                                    <p class="text-dark ">{tservice_mobile("cards_section").android_development_content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='border border-2 mb-3 rounded-3' style={{ height: '100%' }}>
                            <div class="card p-4 pt-5 pb-4 border-0 mt-4">
                                <img class=" mx-auto" style={{ width: '15%' }} src={ios} alt="Card image cap" />
                                <div class="card-body text-center">
                                    <h4 class="card-title fw-bolder main_text pb-2">{tservice_mobile("cards_section").iOSapp_development_title}</h4>
                                    <p class="text-dark">{tservice_mobile("cards_section").iOSapp_development_content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='border border-2 mb-3 rounded-3' style={{ height: '100%' }}>
                            <div class="card p-4 pt-5 pb-4 border-0 mt-4" >
                                <img class=" mx-auto" style={{ width: '15%' }} src={settings} alt="Card image cap" />
                                <div class="card-body text-center">
                                    <h4 class="card-title fw-bolder main_text pb-2">{tservice_mobile("cards_section").crossplatform_appdevelopment_title}</h4>
                                    <p class="text-dark">{tservice_mobile("cards_section").crossplatform_appdevelopment_content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* client section */}
            <div className='py-3'>
                <OurClients />
            </div>

            {/* portfolio section */}
            <div className='container py-5'>
                <h1 className='text-center fw-bold pb-4'>{tservice_mobile("portfolio_section").heading}</h1>
                <div className='row'>
                    <div className='col'>
                        <div class="card border-0 mb-3" style={{ height: '100%' }}>
                            <img src={MobilappPortfolio_lkn} class="card-img-top" alt="..." />
                            <div class="card-body  mt-4 rounded-3 p-4" style={{ backgroundColor: '#292929', color: 'white' }}>
                                <h5 class="card-title">Lakshmi krishna <span><img src={uparrowWhite} /></span></h5>
                                <p class="card-text">{tservice_mobile("portfolio_section").lnk_content}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div class="card border-0 mb-3" style={{ height: '100%' }}>
                            <img src={MobilappPortfolio_bee} class="card-img-top" alt="..." />
                            <div class="card-body  mt-4 rounded-3 p-4" style={{ backgroundColor: '#CECDCB' }}>
                                <h5 class="card-title">Bee Group <span><img src={uparrow} /></span></h5>
                                <p class="card-text">{tservice_mobile("portfolio_section").bee_content}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div class="card border-0 mb-3" style={{ height: '100%' }}>
                            <img src={MobilappPortfolio_accopedia} class="card-img-top" alt="..." />
                            <div class="card-body  mt-4 rounded-3 p-4" style={{ backgroundColor: '#F4F4F4' }}>
                                <h5 class="card-title">Accopedia <span><img src={uparrow} /></span></h5>
                                <p class="card-text">{tservice_mobile("portfolio_section").accopedia_content}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div class="card border-0 mb-3" style={{ height: '100%' }}>
                            <img src={MobilappPortfolio_class} class="card-img-top" alt="..." />
                            <div class="card-body  mt-4 rounded-3 p-4" style={{ backgroundColor: '#292929', color: 'white' }}>
                                <h5 class="card-title">Class Tales <span><img src={uparrowWhite} /></span></h5>
                                <p class="card-text">{tservice_mobile("portfolio_section").clastale_content}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* development stages section */}
            <div className='container-fluid bg-dark'>
                <div className='container py-5 my-3'>
                    <h3 className='text-white text-center pb-3 py-3'>{tservice_mobile("developement_stage_section").heading}</h3 >
                    <div className='row'>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={development_stages_recuremnent} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tservice_mobile("developement_stage_section").requirement_title}</h4>
                                    <p class="card-text">{tservice_mobile("developement_stage_section").requirement_content}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={development_stages_planning} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tservice_mobile("developement_stage_section").planning_title}</h4>
                                    <p class="card-text">{tservice_mobile("developement_stage_section").planning_content}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={development_stages_design} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tservice_mobile("developement_stage_section").design_title}</h4>
                                    <p class="card-text">{tservice_mobile("developement_stage_section").design_content}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={development_stages_development} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tservice_mobile("developement_stage_section").development_title}</h4>
                                    <p class="card-text">{tservice_mobile("developement_stage_section").development_content}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={development_stages_testing} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tservice_mobile("developement_stage_section").testing_title}</h4>
                                    <p class="card-text">{tservice_mobile("developement_stage_section").testing_content}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="card m-2 border-0  bg-transparent text-white" >
                                <div className='text-center'>
                                    <img src={development_stages_deployement} class="w-25" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h4 class="card-title main_text">{tservice_mobile("developement_stage_section").deployment_title}</h4>
                                    <p class="card-text">{tservice_mobile("developement_stage_section").deployment_content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* FAQ Section */}
            <div className='container-fluid py-5 position-relative mb-5'>
                <h3 className='text-center pb-3'>Frequently Asked Questions</h3>
                <div class="accordion  mx-auto faq" id="accordionExample" style={{ width: '75%' }}>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                {tservice_mobile("faq_section").question1}
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_mobile("faq_section").answer1}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                {tservice_mobile("faq_section").question2}
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_mobile("faq_section").answer2}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                {tservice_mobile("faq_section").question3}
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_mobile("faq_section").answer3}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                {tservice_mobile("faq_section").question4}
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_mobile("faq_section").answer4}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                {tservice_mobile("faq_section").question5}
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_mobile("faq_section").answer5}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                {tservice_mobile("faq_section").question6}
                            </button>
                        </h2>
                        <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_mobile("faq_section").answer6}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='position-absolute' style={{ left: 0, bottom: -150 }}>
                    <img src={Shadow} width="65%" style={{ position: 'relative', zIndex: -1 }} />
                </div>
            </div>

            {/* our experts section*/}
            <OurExperts />

        </>
    )
}
