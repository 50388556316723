import React from 'react'
import '../assets/css/OurExperts.css'
import { useTranslation } from 'react-i18next';

export default function OurExperts() {
    const { t: texperts, i18n: i18nexperts } = useTranslation('experts');

    return (
        <div className='container my-5 mt-0 rounded-3 our_expert-bg'>
            <div className='heading p-5 d-flex justify-content-between align-items-center flex-wrap'>
                <div className='w-50'>
                    <p className='text-uppercase m-0 main_text fw-bold'>{texperts("banner").sub_text}</p>
                    <h1 className=' fw-bold'>{texperts("banner").heading}</h1>
                    <p>{texperts("banner").heading_content}</p>
                </div>
                <div>
                    <button className='btn main_bg ps-4 pe-4 text-white'>Book an appointment with our expert now</button>
                </div>
            </div>
        </div>
    )
}
