import React from 'react'
import '../assets/css/Career.css'
import CareerBanner from '../assets/img/CareerBanner.png'
import Company_values from '../assets/img/Company_values.png'
import Friendly_atmospeare from '../assets/img/Friendly_atmospeare.png'
import WorkLifeBalance from '../assets/img/WorkLifeBalance.png'
import EverydayGrow from '../assets/img/EverydayGrow.png'
import flexibleWorkingHrs from '../assets/img/flexibleWorkingHrs.png'
import SportsCompensation from '../assets/img/SportsCompensation.png'
import HealthcareInsurance from '../assets/img/HealthcareInsurance.png'
import HandsClapping from '../assets/img/HandsClapping.png'
import FunEvents from '../assets/img/FunEvents.png'
import RocketLaunch from '../assets/img/RocketLaunch.png'
import Calendar from '../assets/img/Calendar.png'
import Wallet from '../assets/img/Wallet.png'
import Eyeglasses from '../assets/img/Eyeglasses.png'
import GraduationCap from '../assets/img/GraduationCap.png'
import Baby from '../assets/img/Baby.png'
import Target from '../assets/img/Target.png'
import Cake from '../assets/img/Cake.png'
import SocialMeadia1 from '../assets/img/SocialMeadia1.png'
import SocialMeadia2 from '../assets/img/SocialMeadia2.png'
import SocialMeadia3 from '../assets/img/SocialMeadia3.png'
import SocialMeadia4 from '../assets/img/SocialMeadia4.png'
import sm from '../assets/img/sm.png'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import logo from '../assets/img/logo2.png'
import OurClients from '../components/OurClients'
import OurExperts from '../components/OurExperts'



export default function Career() {
    return (
        <>
            {/* banner section */}
            <section className='d-flex align-items-center' style={{height:'80vh'}}>
                <div className='container'>

                    <div className='row '>
                        <div className='text-center'>
                            <button className='btn main_text rounded-pill ps-5 pe-5' style={{ backgroundColor: '#FFE6D2' }}>Karriere</button>
                        </div>
                        <div className='col-12 py-4 col-md'>
                            <div className='center'>

                                <h1>Join the team </h1>
                                <p className='fs-5'> Clapsoft is an employee-centred company that looks after every employee, gives autonomy to make choices, supports self-development and career growth. Our development team is always in search of talented individuals to join our employee-centred culture.</p>
                                <p className='fs-5'>Navigate below to see our current open positions!</p>
                                <button style={{ backgroundColor: '#FD7E14' }} className='btn btn-md me-3 text-white rounded-1 p-2 pe-4 ps-4'>Open Position Now <i class="fa-solid fa-arrow-down-long ps-2"></i></button>
                            </div>
                        </div>
                        <div className='col-12 py-4 col-md'>
                            <img src={CareerBanner} className='' style={{ width: '80%', float: 'right' }} />
                        </div>
                    </div>
                </div>
            </section>

            {/* work place */}
            <div className='container py-5'>
                <h1 className='text-center fw-bold'>What make Clapsoft a great<br /> place to work?</h1>

                <div className='row'>
                    <div className='col-12 col-md-3' >
                        <div class="card mb-3" >
                            <img src={Company_values} class="card-img-top p-3 " alt="..." />
                            <div class="card-body">
                                <h5 class="card-title main_text">Company Values</h5>
                                <p class="card-text">Trust, learning, honesty, and co-operation are the pillars that sit at the core of what we do.</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-md-3' >
                        <div class="card mb-3" >
                            <img src={Friendly_atmospeare} class="card-img-top p-3 " alt="..." />
                            <div class="card-body">
                                <h5 class="card-title main_text">Friendly atmosphere</h5>
                                <p class="card-text">We hold a positive attitude in order to foster respect and decency for our entire team.</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-md-3' >
                        <div class="card mb-3" >
                            <img src={WorkLifeBalance} class="card-img-top p-3 " alt="..." />
                            <div class="card-body">
                                <h5 class="card-title main_text">Work-life balance</h5>
                                <p class="card-text">For us, a healthy personal life is just as important as the time you spend in the office.</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-md-3' >
                        <div class="card mb-3" >
                            <img src={EverydayGrow} class="card-img-top p-3 " alt="..." />
                            <div class="card-body">
                                <h5 class="card-title main_text">Everyday grow</h5>
                                <p class="card-text">We provide the necessary support to ensure your skills never stop growing. </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* benifits section */}
            <div className='container'>
                <h1 className='text-center fw-bold'>What benefits are waiting for you?</h1>
                <p className='text-center'>Clapsoft offers a variety of hand-picked benefits that you can take advantage of!</p>

                <div className='d-flex flex-wrap'>
                    <div className='mobile_grid' style={{ width: '19%', margin: '5px' }}>
                        <div className='d-flex align-items-center rounded-2 p-4 mb-3' style={{ backgroundColor: '#F4F4F4' }}>
                            <img src={flexibleWorkingHrs} alt='...' className='' style={{ width: '45px' }} />
                            <p className='mb-0 ps-2'>Flexible working hours</p>
                        </div>
                    </div>
                    <div className='mobile_grid' style={{ width: '19%', margin: '5px' }}>
                        <div className='d-flex align-items-center rounded-2 p-4 mb-3' style={{ backgroundColor: '#F4F4F4' }}>
                            <img src={SportsCompensation} alt='...' className='' style={{ width: '45px' }} />
                            <p className='mb-0 ps-2'>Sport compensation</p>
                        </div>
                    </div>
                    <div className='mobile_grid' style={{ width: '19%', margin: '5px' }}>
                        <div className='d-flex align-items-center rounded-2 p-4 mb-3' style={{ backgroundColor: '#F4F4F4' }}>
                            <img src={HealthcareInsurance} alt='...' className='' style={{ width: '45px' }} />
                            <p className='mb-0 ps-2'>Health care Insurance</p>
                        </div>
                    </div>
                    <div className='mobile_grid' style={{ width: '19%', margin: '5px' }}>
                        <div className='d-flex align-items-center rounded-2 p-4 mb-3' style={{ backgroundColor: '#F4F4F4' }}>
                            <img src={HandsClapping} alt='...' className='' style={{ width: '45px' }} />
                            <p className='mb-0 ps-2'>Marriage bonus</p>
                        </div>
                    </div>

                    <div className='mobile_grid' style={{ width: '19%', margin: '5px' }}>
                        <div className='d-flex align-items-center rounded-2 p-4 mb-3' style={{ backgroundColor: '#F4F4F4' }}>
                            <img src={FunEvents} alt='...' className='' style={{ width: '45px' }} />
                            <p className='mb-0 ps-2'>Fun team events</p>
                        </div>
                    </div>

                    <div className='mobile_grid' style={{ width: '19%', margin: '5px' }}>
                        <div className='d-flex align-items-center rounded-2 p-4 mb-3' style={{ backgroundColor: '#F4F4F4' }}>
                            <img src={RocketLaunch} alt='...' className='' style={{ width: '45px' }} />
                            <p className='mb-0 ps-2'>Perfessional grow budget</p>
                        </div>
                    </div>

                    <div className='mobile_grid' style={{ width: '19%', margin: '5px' }}>
                        <div className='d-flex align-items-center rounded-2 p-4 mb-3' style={{ backgroundColor: '#F4F4F4' }}>
                            <img src={Calendar} alt='...' className='' style={{ width: '45px' }} />
                            <p className='mb-0 ps-2'>30 days paid vacation</p>
                        </div>
                    </div>
                    <div className='mobile_grid' style={{ width: '19%', margin: '5px' }}>
                        <div className='d-flex align-items-center rounded-2 p-4 mb-3' style={{ backgroundColor: '#F4F4F4' }}>
                            <img src={Wallet} alt='...' className='' style={{ width: '45px' }} />
                            <p className='mb-0 ps-2'>Competitive salary</p>
                        </div>
                    </div>
                    <div className='mobile_grid' style={{ width: '19%', margin: '5px' }}>
                        <div className='d-flex align-items-center rounded-2 p-4 mb-3' style={{ backgroundColor: '#F4F4F4' }}>
                            <img src={Eyeglasses} alt='...' className='' style={{ width: '45px' }} />
                            <p className='mb-0 ps-2'>Compensation for eyeglasses</p>
                        </div>
                    </div>
                    <div className='mobile_grid' style={{ width: '19%', margin: '5px' }}>
                        <div className='d-flex align-items-center rounded-2 p-4 mb-3' style={{ backgroundColor: '#F4F4F4' }}>
                            <img src={GraduationCap} alt='...' className='' style={{ width: '45px' }} />
                            <p className='mb-0 ps-2'>Allowance for first-graders</p>
                        </div>
                    </div>
                    <div className='mobile_grid' style={{ width: '19%', margin: '5px' }}>
                        <div className='d-flex align-items-center rounded-2 p-4 mb-3' style={{ backgroundColor: '#F4F4F4' }}>
                            <img src={Baby} alt='...' className='' style={{ width: '45px' }} />
                            <p className='mb-0 ps-2'>Childbirth allowance</p>
                        </div>
                    </div>
                    <div className='mobile_grid' style={{ width: '19%', margin: '5px' }}>
                        <div className='d-flex align-items-center rounded-2 p-4 mb-3' style={{ backgroundColor: '#F4F4F4' }}>
                            <img src={Target} alt='...' className='' style={{ width: '45px' }} />
                            <p className='mb-0 ps-2'>Employee referral bonus</p>
                        </div>
                    </div>
                    <div className='mobile_grid' style={{ width: '19%', margin: '5px' }}>
                        <div className='d-flex align-items-center rounded-2 p-4 mb-3' style={{ backgroundColor: '#F4F4F4' }}>
                            <img src={Cake} alt='...' className='' style={{ width: '45px' }} />
                            <p className='mb-0 ps-2'>Birthdays celebration</p>
                        </div>
                    </div>

                </div>
            </div>

            {/* timeline section */}
            <div className='container-fluid' style={{ backgroundColor: '#1A202C' }}>
                {/* <div class="timeline w-75">
                    <div class="containers left">
                        <div class="content">
                            <h2>2017</h2>
                            <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
                           
                        </div>
                        <p className='bg-danger'>1</p>
                    </div>
                    <div class="containers right">
                        <div class="content">
                            <h2>2016</h2>
                            <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
                        </div>
                        <p className='bg-success'>1</p>

                    </div>
                    <div class="containers left">
                        <div class="content">
                            <h2>2015</h2>
                            <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
                        </div>
                    </div>
                    <div class="containers right">
                        <div class="content">
                            <h2>2012</h2>
                            <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
                        </div>
                    </div>
                    <div class="containers left">
                        <div class="content">
                            <h2>2011</h2>
                            <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
                        </div>
                    </div>
                    <div class="containers right">
                        <div class="content">
                            <h2>2007</h2>
                            <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
                        </div>
                    </div>
                </div> */}
                <h1 className='text-center main_text pt-5'>How to become a Clapsoft?</h1>
                <p className='text-center text-white'>Joining us couldn't be easier! Check out our application process down below. If you have the talent we need, then<br /> we'll be meeting you soon!</p>
                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#1A202C', color: '', boxShadow: 'none', float: 'right', paddingTop: 0 }}
                        contentArrowStyle={{ borderRight: '7px solid #1A202C' }}
                        date=""
                        iconStyle={{ background: 'white', color: '#fff', boxShadow: '0 0 0 4px #FD7E14' }}
                        icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'black' }}>01</div>}
                    >
                        <h3 className="vertical-timeline-element-title main_text">Find a role that suits you</h3>
                        {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                        <p className='text-white'>
                            Discover open positions and find your desired one in the Visioncraft website,  job listings or social media.
                        </p>
                    </VerticalTimelineElement>


                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#1A202C', color: '', boxShadow: 'none', float: 'left', paddingTop: 0 }}
                        contentArrowStyle={{ borderRight: '7px solid #1A202C' }}
                        date=""
                        iconStyle={{ background: 'white', color: '#fff', boxShadow: 'none' }}
                        icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'black' }}>02</div>}
                    >
                        <h3 className="vertical-timeline-element-title main_text">Send your application</h3>
                        {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                        <p className='text-white'>
                            Some simple questions should be answered and your contact information is required
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#1A202C', color: '', boxShadow: 'none', float: 'right', paddingTop: 0 }}
                        contentArrowStyle={{ borderRight: '7px solid #1A202C' }}
                        date=""
                        iconStyle={{ background: 'white', color: '#fff', boxShadow: 'none' }}
                        icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'black' }}>03</div>}
                    >
                        <h3 className="vertical-timeline-element-title main_text">Receive your interview invite</h3>
                        {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                        <p className='text-white'>
                            We review all applications within 3 working days and send invitation to candidates.
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#1A202C', color: '', boxShadow: 'none', float: 'left', paddingTop: 0 }}
                        contentArrowStyle={{ borderRight: '7px solid #1A202C' }}
                        date=""
                        iconStyle={{ background: 'white', color: '#fff', boxShadow: 'none' }}
                        icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'black' }}>04</div>}
                    >
                        <h3 className="vertical-timeline-element-title main_text">Choose an interview slot</h3>
                        {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                        <p className='text-white'>
                            You will have a friendly discution with the CEO and your  supervisor to talk about the work, life and etc.
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#1A202C', color: '', boxShadow: 'none', float: 'right', paddingTop: 0 }}
                        contentArrowStyle={{ borderRight: '7px solid #1A202C' }}
                        date=""
                        iconStyle={{ background: 'white', color: '#fff', boxShadow: 'none' }}
                        icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'black' }}>05</div>}
                    >
                        <h3 className="vertical-timeline-element-title main_text">Preliminary Interview</h3>
                        {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                        <p className='text-white'>
                            Sometimes, we ask candidates to participate in some technical challenge that is designated to demonstrate candidates' proficiency.
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#1A202C', color: '', boxShadow: 'none', float: 'left', paddingTop: 0 }}
                        contentArrowStyle={{ borderRight: '7px solid #1A202C' }}
                        date=""
                        iconStyle={{ background: 'white', color: '#fff', boxShadow: 'none' }}
                        icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'black' }}>06</div>}
                    >
                        <h3 className="vertical-timeline-element-title main_text">Meet the your teammates</h3>
                        {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                        <p className='text-white'>
                            To us is crucial to make sure all team members feel comfortable.  It is why we do try to have diverse but culturally fitted team members.
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#1A202C', color: '', boxShadow: 'none', float: 'right', paddingTop: 0 }}
                        contentArrowStyle={{ borderRight: '7px solid #1A202C' }}
                        date=""
                        iconStyle={{ background: 'white', color: '#fff', boxShadow: 'none' }}
                        icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'black' }}>07</div>}
                    >
                        <h3 className="vertical-timeline-element-title main_text">Interview with our CEO</h3>
                        {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                        <p className='text-white'>
                            Your colleagues are waiting for you to say a warm welcome.
                        </p>
                    </VerticalTimelineElement>



                    {/* <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2010 - 2011"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>2</div>}
                    >
                        <h3 className="vertical-timeline-element-title">Art Director</h3>
                        <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
                        <p>
                            Creative Direction, User Experience, Visual Design, SEO, Online Marketing
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2008 - 2010"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>3</div>}
                    >
                        <h3 className="vertical-timeline-element-title">Web Designer</h3>
                        <h4 className="vertical-timeline-element-subtitle">Los Angeles, CA</h4>
                        <p>
                            User Experience, Visual Design
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2006 - 2008"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>4</div>}
                    >
                        <h3 className="vertical-timeline-element-title">Web Designer</h3>
                        <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
                        <p>
                            User Experience, Visual Design
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="April 2013"
                        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>5</div>}
                    >
                        <h3 className="vertical-timeline-element-title">Content Marketing for Web, Mobile and Social Media</h3>
                        <h4 className="vertical-timeline-element-subtitle">Online Course</h4>
                        <p>
                            Strategy, Social Media
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="November 2012"
                        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>6</div>}
                    >
                        <h3 className="vertical-timeline-element-title">Agile Development Scrum Master</h3>
                        <h4 className="vertical-timeline-element-subtitle">Certification</h4>
                        <p>
                            Creative Direction, User Experience, Visual Design
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="2002 - 2006"
                        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>7</div>}
                    >
                        <h3 className="vertical-timeline-element-title">Bachelor of Science in Interactive Digital Media Visual Imaging</h3>
                        <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4>
                        <p>
                            Creative Direction, Visual Design
                        </p>
                    </VerticalTimelineElement> */}
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#1A202C', color: '', boxShadow: 'none', float: 'left', paddingTop: 0 }}
                        contentArrowStyle={{ borderRight: '7px solid #1A202C' }}
                        date=""
                        iconStyle={{ background: 'white', color: '#fff', boxShadow: 'none' }}
                        icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', }} className='fs-4'>👏</div>}

                    >
                        <h3 className="vertical-timeline-element-title main_text">Start a new journey!</h3>

                    </VerticalTimelineElement>
                </VerticalTimeline>

            </div>

            {/* job openings section */}
            <div className='container py-5'>
                <h2 className='text-center fw-bold'>We have 17 open positions now!</h2>
                <div className='row mx-auto py-5' style={{ width: '80%' }}>
                    <div className='col-12 col-md-3'>
                        <p style={{ borderLeft: '4px solid #FD7E14' }} className='ps-4'>Browse by: <i class="fa-solid fa-location-dot"></i> Location</p>
                        <p className='main_text ps-4'>German (5)</p>
                        <p className='ps-4'>India (3)</p>
                        <p>We are always seeking talented people. In case you cannot find your desired position here, please send us your LinkedIn profile and give us your contact information. We will be in touch. </p>
                    </div>
                    <div className='col-12 col-md-9'>
                        <div className='ps-5 pb-5'>
                            <h3>Full-Stack Developers </h3>
                            <button className='btn main_bg text-white'>Tartu</button> <button className='btn btn main_bg text-white'>Full-time</button>
                            <p className='pt-3'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                            <button className='btn btn-dark float-end'>Apply Now  <i class="fa-solid fa-arrow-right-long ps-2"></i></button>
                        </div>

                        <div className='ps-5 pb-5'>
                            <h3>Application developer (react native)</h3>
                            <button className='btn main_bg text-white'>Tartu</button> <button className='btn btn main_bg text-white'>Full-time</button>
                            <p className='pt-3'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                            <button className='btn btn-dark float-end'>Apply Now  <i class="fa-solid fa-arrow-right-long ps-2"></i></button>
                        </div>

                        <div className='ps-5 pb-5'>
                            <h3>Senior Product designer </h3>
                            <button className='btn main_bg text-white'>Tartu</button> <button className='btn btn main_bg text-white'>Full-time</button>
                            <p className='pt-3'>Since 2019 we’ve worked on 30+ major projects from 8 different industries that are being used by 500,000+ users and 1000+ businesses from 70+ different countries. Need full-cycle product development or an improvement cycle? Let’s talk!</p>
                            <button className='btn btn-dark float-end'>Apply Now  <i class="fa-solid fa-arrow-right-long ps-2"></i></button>
                        </div>

                        <div className='ps-5 pb-5'>
                            <h3>Product Manager </h3>
                            <button className='btn main_bg text-white'>Tartu</button> <button className='btn btn main_bg text-white'>Full-time</button>
                            <p className='pt-3'>If you are PM and you eager to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features. 30+ major projects from 8 different industries that are being used by 500,000+ users and 1000+ businesses from 70+ different countries.</p>
                            <button className='btn btn-dark float-end'>Apply Now  <i class="fa-solid fa-arrow-right-long ps-2"></i></button>
                        </div>


                        <div className='ps-5 pb-5'>
                            <h3>Product Owner </h3>
                            <button className='btn main_bg text-white'>Tartu</button> <button className='btn btn main_bg text-white'>Full-time</button>
                            <p className='pt-3'>We’ve worked on 30+ major projects from 8 different industries that are being . Need full-cycle product development or an improvement cycle? Let’s talk!</p>
                            <button className='btn btn-dark float-end'>Apply Now  <i class="fa-solid fa-arrow-right-long ps-2"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            {/* social section */}
            <div className='container my-5'>
                <div className='row'>
                    <div className='col'>
                        <h3 className='fw-bold'>Follow us on social to see what we're up to!</h3>
                        <img src={SocialMeadia1} alt='...' className='w-100' />
                    </div>
                    <div className='col'>
                        <img src={sm} alt='...' className='' style={{ width: '80%' }} />
                    </div>

                    <div className='col'>
                        <div className='p-0'>
                            <img src={logo} className='w-100' />
                            <p>We are eager to be in touch with you in the following channels:</p>
                            {/* <div className='d-flex align-items-center'>
                                <div className='d-flex align-items-center justify-content-center' style={{ width: '40px', height: '40px', backgroundColor: '#FD7E14', borderRadius: '50%' }}>
                                    <i class="fab fa-facebook-f text-white" style={{ fontSize: "24px" }}></i>

                                </div>
                                <div>
                                    <p>hiii</p>
                                </div>

                            </div> */}

                            <div className='d-flex flex-wrap align-items-center justify-content-center'>
                                <div className='d-flex w-50  align-items-center mb-3'>
                                    <div>
                                        <div className='d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', backgroundColor: '#FD7E14', borderRadius: '50%' }}>
                                            <i class="fab fa-facebook-f text-white" style={{ fontSize: "20px" }}></i>

                                        </div>
                                    </div>
                                    <div>
                                        <p className='mb-0 ps-2'>Facebook</p>
                                    </div>

                                </div>

                                <div className='d-flex w-50  align-items-center mb-3'>
                                    <div>
                                        <div className='d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', backgroundColor: '#FD7E14', borderRadius: '50%' }}>
                                            <i class="fa-brands fa-youtube text-white" style={{ fontSize: "20px" }}></i>

                                        </div>
                                    </div>
                                    <div>
                                        <p className='mb-0 ps-2'>Youtube</p>
                                    </div>

                                </div>

                                <div className='d-flex w-50  align-items-center mb-3'>
                                    <div>
                                        <div className='d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', backgroundColor: '#FD7E14', borderRadius: '50%' }}>
                                            <i class="fa-brands fa-twitter text-white" style={{ fontSize: "20px" }}></i>

                                        </div>
                                    </div>
                                    <div>
                                        <p className='mb-0 ps-2'>Twiter</p>
                                    </div>

                                </div>

                                <div className='d-flex w-50  align-items-center mb-3'>
                                    <div>
                                        <div className='d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', backgroundColor: '#FD7E14', borderRadius: '50%' }}>
                                            <i class="fa-brands fa-instagram text-white" style={{ fontSize: "20px" }}></i>

                                        </div>
                                    </div>
                                    <div>
                                        <p className='mb-0 ps-2'>Instagram</p>
                                    </div>

                                </div>
                                <div className='d-flex w-50  align-items-center mb-3'>
                                    <div>
                                        <div className='d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', backgroundColor: '#FD7E14', borderRadius: '50%' }}>
                                            <i class="fa-brands fa-linkedin-in text-white" style={{ fontSize: "20px" }}></i>

                                        </div>
                                    </div>
                                    <div>
                                        <p className='mb-0 ps-2'>Linked In</p>
                                    </div>

                                </div>
                            </div>

                            {/* <div className='row'>
                                <div className='col'></div>
                                <div className='col'></div>
                                <div className='col'></div>
                                
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* client section */}
            <OurClients />

            {/* our experts section */}
            <OurExperts />
        </>
    )
}
