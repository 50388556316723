import React from 'react'
import portfolioBanner from '../assets/img/portfolioBanner.png'
import classmateCol from '../assets/img/classmateCol.png'
import BeeCol from '../assets/img/BeeCol.png'
import krishnabavanCol from '../assets/img/krishnabavanCol.png'
import AccupediaCol from '../assets/img/AccupediaCol.png'
import lknCol from '../assets/img/lknCol.png'
import OurClients from '../components/OurClients'
import OurExperts from '../components/OurExperts'
import { useTranslation } from 'react-i18next'




export default function Portfolio() {
    const { t: tportfolio, i18n: i18nportfolio } = useTranslation('portfolio');

    return (
        <>
            {/* banner section */}
            <div className='container text-center'>
                <button className='btn main_text rounded-pill ps-4 pe-4 mb-3 text-uppercase' style={{ backgroundColor: '#FFE6D2' }}>{tportfolio("banner").sub_heading}</button>
                <h1 className='fw-bolder'>{tportfolio("banner").heading}</h1>
                <p>Our projects are born from a passion for innovation that knows no bounds. We see<br /> challenges as opportunities to push the envelope and explore new frontiers.</p>
                <img src={portfolioBanner} className='w-25' />
            </div>

            <div className='container py-5'>

                <div className='d-flex align-items-center manufact_m_pad' id='agriculture'>
                    <div className='row w-100 ' style={{ paddingTop: '70px' }}>
                        <div className='col-12 col-sm-5 col-md-5 col-lg-5'>
                            <img src={classmateCol} class="d-block w-100" alt="..." />
                        </div>
                        <div className='col d-flex align-items-center'>
                            <div className="ps-5 fp">
                                <h2 className=''>CLASSTALES</h2>
                                <p className='fs-5'>
                                    {tportfolio("clasetale_section").content1}
                                </p>
                                <p className='fs-5'>
                                    {tportfolio("clasetale_section").content2}
                                </p>
                                <p className='fs-5'>
                                    {tportfolio("clasetale_section").content3}
                                </p>

                                <a href='https://play.google.com/store/apps/details?id=com.classtales&hl=en_AU' target='_blank' className='btn main_bg text-white btn-md p-5 pt-2 pb-2' style={{ backgroundColor: '' }}>View Our Site</a>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center manufact_m_pad' id='seeds'>
                    <div className='row w-100 ' style={{ paddingTop: '70px' }}>
                        <div className='col order-sm-2 order-lg-1 order-md-1 d-flex align-items-center'>
                            <div className="mt-2 p-5 ps-0  fp">
                                <h2 className=''>BEE GROUP</h2>
                                <p className='fs-5'>
                                    {tportfolio("bee_section").content}
                                </p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-5 col-md-5 col-lg-5 order-sm-1 order-lg-2 order-md-2'>
                            <img src={BeeCol} class="d-block w-100" alt="..." />
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center manufact_m_pad' id='agriculture'>
                    <div className='row w-100 ' style={{ paddingTop: '70px' }}>
                        <div className='col-12 col-sm-5 col-md-5 col-lg-5'>
                            <img src={krishnabavanCol} class="d-block w-100" alt="..." />
                        </div>
                        <div className='col d-flex align-items-center'>
                            <div className="ps-5 fp">
                                <h2 className='fw-bolder'>KRISHNA BHAVAN FOODS & SWEETS</h2>
                                <p className='fs-5'>
                                    {tportfolio("kb_section").content}
                                </p>
                                <p className='fs-5'>
                                    {tportfolio("kb_section").point1}
                                </p>
                                <p className='fs-5'>
                                    {tportfolio("kb_section").point2}

                                </p>
                                <p className='fs-5'>
                                    {tportfolio("kb_section").point3}
                                </p>

                                <a href='https://krishnabhavanfoods.in/' target='_blank' className='btn main_bg text-white btn-md p-5 pt-2 pb-2' style={{ backgroundColor: '' }}>View Our Site</a>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center manufact_m_pad' id='seeds'>
                    <div className='row w-100 ' style={{ paddingTop: '70px' }}>
                        <div className='col order-sm-2 order-lg-1 order-md-1 d-flex align-items-center'>
                            <div className="mt-2 p-5 ps-0  fp">
                                <h2 className='fw-bolder'>LAKSHMI KRISHNA NATURAL<br />
                                    HOMEMADE PRODUCTS</h2>
                                <p className='fs-5'><br />
                                    {tportfolio("lkn_section").content}
                                </p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-5 col-md-5 col-lg-5 order-sm-1 order-lg-2 order-md-2'>
                            <img src={lknCol} class="d-block w-100" alt="..." />
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center manufact_m_pad' id='agriculture'>
                    <div className='row w-100 ' style={{ paddingTop: '70px' }}>
                        <div className='col-12 col-sm-5 col-md-5 col-lg-5'>
                            <img src={AccupediaCol} class="d-block w-100" alt="..." />
                        </div>
                        <div className='col d-flex align-items-center'>
                            <div className="ps-5 fp">
                                <h2 className=''>ACCOPEDIA</h2>
                                <p className='fs-5'>
                                    {tportfolio("accopedia_section").content}

                                </p>
                                <a href='https://play.google.com/store/apps/details?id=co.groot.runyo&hl=en&gl=US' target='_blank' className='btn btn-dark rounded-pill ps-4 pe-4'>{tportfolio("accopedia_section").button1}</a> <button className='btn btn-dark rounded-pill ps-4 pe-4'>{tportfolio("accopedia_section").button2}</button><br /><br />
                                <a href='https://accopedia.com/' target='_blank' className='btn main_bg text-white btn-md p-5 pt-2 pb-2' style={{ backgroundColor: '' }}>View Our Site</a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='row py-2'>
                    <div className='col-12 col-md-5'>
                        <img src={classmateCol} alt='...' className='' style={{ width: '85%' }} />
                    </div>
                    <div className='col-12 col-md d-flex align-items-center'>
                        <div>
                            <h4 className='fw-bolder'>CLASSTALES</h4>
                            <p className='fs-5'>
                                ClassTales is a platform where teachers, parents and the school can communicate, share stories about daily activities, progress, school events, registration and feedback.
                            </p>
                            <p className='fs-5'>
                                Teachers do not need to share their personal number but they can stay connected with the parents.
                            </p>
                            <p className='fs-5'>
                                Parents can view the updates from the teachers, clarify their questions, schedule PTM meetings, receive important notifications.
                            </p>

                            <button className='btn main_bg text-white btn-md p-5 pt-2 pb-2' style={{ backgroundColor: '' }}>View Our Site</button>
                        </div>
                    </div>
                </div> */}
                {/* 
                <div className='row py-2'>
                    <div className='col-12 col-md d-flex align-items-center'>
                        <div>
                            <h4 className='fw-bolder'>BEE GROUP</h4>
                            <p className='fs-5'>
                                Cameroon based company that does Food delivery, people
                                transport and goods transport.
                            </p>

                        </div>
                    </div>
                    <div className='col-12 col-md-5'>
                        <img src={BeeCol} alt='...' className='w-100' />
                    </div>

                </div> */}

                {/* <div className='row py-2'>
                    <div className='col-12 col-md-5'>
                        <img src={krishnabavanCol} alt='...' className='w-100' />
                    </div>
                    <div className='col-12 col-md d-flex align-items-center'>
                        <div>
                            <h4 className='fw-bolder'>KRISHNA BHAVAN FOODS & SWEETS</h4>
                            <p className='fs-5'>
                                Here’s everything you can do and find on the Krishna Bhavan Reps App.
                            </p>
                            <p className='fs-5'>
                                1) CRM Master :-<br />• This tab has the complete Store details of the Customers of Krishna Bhavan Foods & Sweets.
                            </p>
                            <p className='fs-5'>
                                2) Products Master :-•<br /> This tab has the complete Products details of the products available at Krishna Bhavan Foods & Sweets.
                            </p>
                            <p className='fs-5'>
                                3) New Order :-<br />• Reps can place Orders through this App with ease.<br />• Manage Safety Stock while taking Orders itself.
                            </p>

                            <button className='btn main_bg text-white btn-md p-5 pt-2 pb-2' style={{ backgroundColor: '' }}>View Our Site</button>
                        </div>
                    </div>
                </div> */}

                {/* <div className='row py-2'>
                    <div className='col-12 col-md d-flex align-items-center'>
                        <div>
                            <h4 className='fw-bolder'>LAKSHMI KRISHNA NATURAL<br />
                                HOMEMADE PRODUCTS</h4>
                            <p className='fs-5'><br />
                                Lakshmi krishna Natural Homemade Organic Products .
                            </p>

                        </div>
                    </div>
                    <div className='col-12 col-md-5'>
                        <img src={lknCol} alt='...' className='w-100' />
                    </div>

                </div> */}

                {/* <div className='row py-2'>
                    <div className='col-12 col-md-5'>
                        <img src={AccupediaCol} alt='...' className='w-100' />
                    </div>
                    <div className='col-12 col-md d-flex align-items-center'>
                        <div>
                            <h4 className='fw-bolder'>ACCOPEDIA</h4>
                            <p className='fs-5'>
                                Our team has developed an online tool to facilitate the workflow of the office activities. It can be used by Chartered Accountants, Company Secretaries, Cost Accountants, ITP, STP/GSTP.The App is available in play store and will be uploaded shortly in the app store as well.
                            </p>
                            <button className='btn btn-dark rounded-pill ps-4 pe-4'>Android</button> <button className='btn btn-dark rounded-pill ps-4 pe-4'>Financial</button><br /><br />
                            <button className='btn main_bg text-white btn-md p-5 pt-2 pb-2' style={{ backgroundColor: '' }}>View Our Site</button>
                        </div>
                    </div>
                </div> */}
            </div>

            {/* client section */}
            <OurClients />

            {/* our experts section */}
            <OurExperts />
        </>
    )
}
