import React from 'react'
import client1 from '../assets/img/client1.png'
import client2 from '../assets/img/client2.png'
import client3 from '../assets/img/client3.png'
import client4 from '../assets/img/client4.png'
import client5 from '../assets/img/client5.png'
import { useTranslation } from 'react-i18next'


export default function OurClients() {

    const { t: tclients, i18n: i18nclients } = useTranslation('clients');

    return (
        <>
            <div className='container pb-3'>
                <div className='heading text-center pb-4'>
                    <p className='text-uppercase m-0 main_text fw-bold'>{tclients("header").sub_text}</p>
                    <h1 className=' fw-bold'>{tclients("header").heading}</h1>
                </div>
                <div className='d-flex justify-content-evenly flex-wrap'>
                    <div><img src={client1} alt='...' className='' /></div>
                    <div><img src={client2} alt='...' className='' /></div>
                    <div><img src={client3} alt='...' className='' /></div>
                    <div><img src={client4} alt='...' className='' /></div>
                    <div><img src={client5} alt='...' className='' /></div>
                </div>
            </div>
        </>
    )
}
