import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Test from './components/Test';
import Home from './pages/Home';
import PageRoutes from './components/PageRoutes';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Updated import statement
import ScrollToTop from './components/ScrollToTop';


function App() {
  return (

    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path='/*' element={<PageRoutes />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<h2>Page not found</h2>} />
        </Routes>
        {/* <Footer/> */}
      </Router>
    </>
  );
}

export default App;
