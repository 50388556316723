import React from 'react';
import '../assets/css/Footer.css';
import logo from '../assets/img/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faPhone, faLocationDot, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import location from '../assets/img/clapsoft_location.png'



const Footer = () => {
    const { t: tfooter, i18n: i18nfooter } = useTranslation('footer');
    return (
        <>

            <div className='py-5 pb-0 text-white footerBg' >
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-3 py-3'>
                            <a class=" text-white" href="#" ><img src={logo} className='w-50' /></a>
                            <p className='pt-2'>
                                {tfooter("info").info_content}
                            </p>
                        </div>
                        <div className='col-12 col-md-3 py-3'>
                            <h4 className='' style={{ color: '#FD7E14', paddingTop: '' }}>Get in Touch</h4>
                            <div className='pt-4'>
                                <p className=''>
                                    <FontAwesomeIcon icon={faPhone} className="pe-3" style={{ color: '#FD7E14' }} />

                                    +49 72323090134</p>
                                <p>
                                    <FontAwesomeIcon icon={faLocationDot} className="pe-3" style={{ color: '#FD7E14' }} />
                                    {/* 8819 Ohio St. South Gate,
                                    CA 90280 */}
                                    Pforzheimer Str. 26<br/>
                                    <span className='ps-4'>75196 Remchingen</span>
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faEnvelope} className="pe-3" style={{ color: '#FD7E14' }} />
                                    contact@clapsoft.de</p>
                            </div>

                        </div>
                        <div className='col-12 col-md-2 py-3'>
                            <div>
                                <div className='footer_socialIcons'>
                                    <ul class="list-unstyled d-flex justify-content-between text-line">
                                        <li class="d-inline text-center " >

                                            <FontAwesomeIcon className='rounded-circle' icon={['fab', 'facebook-f']} style={{
                                                width: '20px', height: '20px', padding: '55%', backgroundColor: '#FD7E14'
                                            }} />

                                        </li>
                                        <li class="d-inline text-center " >

                                            <FontAwesomeIcon className='rounded-circle' icon={faTwitter} style={{
                                                width: '20px', height: '20px', padding: '55%', backgroundColor: '#FD7E14'
                                            }} />


                                        </li>
                                        <li class="d-inline text-center " >

                                            <FontAwesomeIcon className='rounded-circle' icon={faLinkedinIn} style={{
                                                width: '20px', height: '20px', padding: '55%', backgroundColor: '#FD7E14'
                                            }} />
                                        </li>

                                    </ul>

                                </div>
                                <p>      <FontAwesomeIcon icon={faClock} className="pe-2" />

                                    Mon - Fri<br />
                                    9:00am - 6:00pm</p>
                                <p> <FontAwesomeIcon icon={faClock} className="pe-2" />
                                    Sat - Sun<br />
                                    Weekly Off
                                </p>
                            </div>
                        </div>
                        <div className='col-12 col-md-4 py-3'>
                            <h4 className='pb-2' style={{ color: '#FD7E14', paddingTop: '' }}>Maps Location</h4>
                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2782.999556506952!2d4.895346421447128!3d45.771199137350784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4c047b6c72c41%3A0x3686f6ff6e91f22!2s161%20Rue%20du%208%20Mai%201945%2C%2069100%20Villeurbanne%2C%20France!5e0!3m2!1sen!2sin!4v1714979460164!5m2!1sen!2sin" width="100%" height="300" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            <img src={location} className='w-100' style={{height:'auto'}} />
                        </div>
                    </div>

                </div>
                <hr />
                <div className='text-center text-white py-3'>
                    <p className='m-0'>Copyright © 2024. All Rights Reserved.</p>
                </div>
            </div>
        </>
    );
};

export default Footer;
