import React from 'react'
import { Route, Router, Routes } from 'react-router-dom'
import Contact from '../pages/Contact'
import About from '../pages/About'
import Nav from './Nav'
import Footer from './Footer'
import ServiceMobile from '../pages/ServiceMobile'
import ServicePrototype from '../pages/ServicePrototype'
import ServicePlatform from '../pages/ServicePlatform'
import ServiceDesign from '../pages/ServiceDesign'
import ServiceECommerce from '../pages/ServiceECommerce'
import ServiceTesting from '../pages/ServiceTesting'
import ServiceManagedIT from '../pages/ServiceManagedIT'
import ServiceRecruite from '../pages/ServiceRecruite'
import Process from '../pages/Process'
import Portfolio from '../pages/Portfolio'
import OurTeam from '../pages/OurTeam'
import Career from '../pages/Career'
import ZohoImplementation from '../pages/ZohoImplementation'

export default function PageRoutes() {
    return (
        <>
            <Nav/>
            <Routes>
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/app_development" element={<ServiceMobile />} />
                <Route path="/prototype_development" element={<ServicePrototype />} />
                <Route path="/platform_development" element={<ServicePlatform />} />
                <Route path="/design" element={<ServiceDesign />} />
                <Route path="/eCommerce_shop_development" element={<ServiceECommerce />} />
                <Route path="/testing_and_services" element={<ServiceTesting />} />
                <Route path="/temporary_employment" element={<ServiceManagedIT />} />
                <Route path="/recruitment" element={<ServiceRecruite />} />
                <Route path="/process" element={<Process />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/our_team" element={<OurTeam />} />
                <Route path="/career" element={<Career />} />
                <Route path="/zoho_implementierung" element={<ZohoImplementation />} />
                <Route path="*" element={<h2>Page not found</h2>} />
            </Routes>
            <Footer/>
        </>
    )
}
