import React from 'react'
import recruiteBanner from '../assets/img/recruiteBanner.png'
import php_icon from '../assets/img/php_icon.png'
import android_icon from '../assets/img/android_icon.png'
import apple_icon from '../assets/img/apple_icon.png'
import pencilEdit_icon from '../assets/img/pencilEdit_icon.png'
import scetch_icon from '../assets/img/scetch_icon.png'
import nodejs_icon from '../assets/img/nodejs_icon.png'
import angular_icon from '../assets/img/angular_icon.png'
import reactNative_icon from '../assets/img/reactNative_icon.png'
import HireStepsBanner from '../assets/img/HireStepsBanner.png'
import checklist_color from '../assets/img/checklist_color.png'
import checklist_black from '../assets/img/checklist_black.png'
import OurClients from '../components/OurClients'
import OurExperts from '../components/OurExperts'
import { useTranslation } from 'react-i18next'


export default function ServiceRecruite() {
    const { t: tclients, i18n: i18ntclients } = useTranslation('clients');
    const { t: tservice_recruite, i18n: i18nservice_recruite } = useTranslation('service_recruite');
    const { t: tcommon, i18n: i18ncommon } = useTranslation('common');
    return (
        <>
            {/* banner section */}
            <div className='container text-center'>
                <button className='btn main_text rounded-pill ps-4 pe-4 text-uppercase mb-3' style={{ backgroundColor: '#FFE6D2' }}>{tservice_recruite("banner").sub_heading}</button>
                <h1 className='fw-bolder'>{tservice_recruite("banner").heading}</h1>
                <img src={recruiteBanner} className='w-50' />
            </div>

            {/* IT service section */}
            <div className='container-fluid my-5' style={{ backgroundColor: '#1C1C1C' }}>
                <div className='container py-5'>
                    <div className='heading '>
                        <p className='text-center text-uppercase m-0 main_text fw-bold'>{tservice_recruite("it_services_section").sub_text}</p>
                        <h2 className='text-center fw-bold text-white'>{tservice_recruite("it_services_section").heading}</h2>
                        <div className='row pt-5'>
                            <div className='col-12 col-md-3'>
                                <div class="card mb-4" style={{ height: 200 }} >
                                    <div class="card-body d-flex flex-column justify-content-center">
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <img src={php_icon} className='' style={{ width: '' }} alt='...' />
                                            <h5 class="card-title ps-2 pb-0 mb-0">{tservice_recruite("it_services_section").php_title}</h5>
                                        </div>
                                        <p class="card-text ">{tservice_recruite("it_services_section").php_content}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-3'>
                                <div class="card mb-4" style={{ height: 200 }} >
                                    <div class="card-body d-flex flex-column justify-content-center">
                                        <div className='d-flex justify-content-start align-items-center pb-3'>
                                            <img src={android_icon} className='' style={{ width: '20%' }} alt='...' />
                                            <h5 class="card-title ps-2 mb-0">{tservice_recruite("it_services_section").android_title}</h5>
                                        </div>
                                        <p class="card-text">{tservice_recruite("it_services_section").android_content}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-3'>
                                <div class="card mb-4" style={{ height: 200 }} >
                                    <div class="card-body d-flex flex-column justify-content-center">
                                        <div className='d-flex justify-content-start align-items-center pb-3'>
                                            <img src={apple_icon} className='' style={{ width: '18%' }} alt='...' />
                                            <h5 class="card-title ps-2 mb-0">{tservice_recruite("it_services_section").ios_title}</h5>
                                        </div>
                                        <p class="card-text ">{tservice_recruite("it_services_section").ios_content}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-3'>
                                <div class="card mb-4" style={{ height: 200 }} >
                                    <div class="card-body d-flex flex-column justify-content-center">
                                        <div className='d-flex justify-content-start align-items-center pb-3'>
                                            <img src={pencilEdit_icon} className='' style={{ width: '20%' }} alt='...' />
                                            <h5 class="card-title ps-2 mb-0">{tservice_recruite("it_services_section").frontend_title}</h5>
                                        </div>
                                        <p class="card-text ">{tservice_recruite("it_services_section").frontend_content}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-3'>
                                <div class="card mb-4" style={{ height: 200 }} >
                                    <div class="card-body d-flex flex-column justify-content-center">
                                        <div className='d-flex justify-content-start align-items-center pb-3'>
                                            <img src={scetch_icon} className='' style={{ width: '40px' }} alt='...' />
                                            <h5 class="card-title ps-2 mb-0">{tservice_recruite("it_services_section").ui_ux_title}</h5>
                                        </div>
                                        <p class="card-text ">{tservice_recruite("it_services_section").ui_ux_content}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-3'>
                                <div class="card mb-4" style={{ height: 200 }} >
                                    <div class="card-body d-flex flex-column justify-content-center">
                                        <div className='d-flex  justify-content-start align-items-center pb-3'>
                                            <img src={nodejs_icon} className='' style={{ width: '20%' }} alt='...' />
                                            <h5 class="card-title ps-2 mb-0">{tservice_recruite("it_services_section").node_title}</h5>
                                        </div>
                                        <p class="card-text ">{tservice_recruite("it_services_section").node_content}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-3'>
                                <div class="card mb-4" style={{ height: 200 }} >
                                    <div class="card-body d-flex flex-column justify-content-center ">
                                        <div className='d-flex  justify-content-start align-items-center pb-3'>
                                            <img src={angular_icon} className='' style={{ width: '15%' }} alt='...' />
                                            <h5 class="card-title ps-2 mb-0">{tservice_recruite("it_services_section").angular_title}</h5>
                                        </div>
                                        <p class="card-text ">{tservice_recruite("it_services_section").angular_content}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-3'>
                                <div class="card mb-4" style={{ height: 200 }} >
                                    <div class="card-body d-flex flex-column justify-content-center">
                                        <div className='d-flex justify-content-start align-items-center pb-3'>
                                            <img src={reactNative_icon} className='' style={{ width: '17%' }} alt='...' />
                                            <h5 class="card-title ps-2 mb-0">{tservice_recruite("it_services_section").rn_title}</h5>
                                        </div>
                                        <p class="card-text ">{tservice_recruite("it_services_section").rn_content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* hire steps */}
            <div className='container pb-5'>
                <p className='text-center text-uppercase m-0 main_text fw-bold'>{tservice_recruite("process_section").sub_text}</p>
                <h2 className='text-center fw-bold'>{tservice_recruite("process_section").heading}</h2>
                <div className='hire_steph_banner py-5 text-center'>
                    <img src={HireStepsBanner} alt='...' className='w-75 mx-auto' />
                </div>

                <div className='container '>
                    <div className='d-flex flex-wrap justify-content-evenly'>
                        <div class="card my-3" style={{ width: "25rem", backgroundColor: '#FFF9F5' }}>
                            <div class="card-body">
                                <h4 class="card-title">Remote</h4>
                                <hr />
                                <div className='d-flex '>
                                    <div>
                                        <img src={checklist_color} alt='..' className='w-100' style={{ width: '', height: 'auto' }} />
                                    </div>
                                    <div>
                                        <p className='ps-2'><span className='fw-bolder'>{tservice_recruite("cards_section").title1}</span> <br />{tservice_recruite("cards_section").content1}</p>
                                    </div>
                                </div>

                                <div className='d-flex '>
                                    <div>
                                        <img src={checklist_color} alt='..' className='w-100' style={{ width: '', height: 'auto' }} />
                                    </div>
                                    <div>
                                        <p className='ps-2'><span className='fw-bolder'>{tservice_recruite("cards_section").title2} </span> <br />{tservice_recruite("cards_section").content2}</p>
                                    </div>
                                </div>

                                <div className='d-flex '>
                                    <div>
                                        <img src={checklist_color} alt='..' className='w-100' style={{ width: '', height: 'auto' }} />
                                    </div>
                                    <div>
                                        <p className='ps-2'><span className='fw-bolder'>{tservice_recruite("cards_section").title3}</span> <br />{tservice_recruite("cards_section").content3}</p>
                                    </div>
                                </div>

                                <div className='d-flex '>
                                    <div>
                                        <img src={checklist_color} alt='..' className='w-100' style={{ width: '', height: 'auto' }} />
                                    </div>
                                    <div>
                                        <p className='ps-2'><span className='fw-bolder'>{tservice_recruite("cards_section").title4}</span> <br />{tservice_recruite("cards_section").content4}</p>
                                    </div>
                                </div>

                                <div className='d-flex '>
                                    <div>
                                        <img src={checklist_color} alt='..' className='w-100' style={{ width: '', height: 'auto' }} />
                                    </div>
                                    <div>
                                        <p className='ps-2'><span className='fw-bolder'>{tservice_recruite("cards_section").title5}</span> <br />{tservice_recruite("cards_section").content5}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card my-3" style={{ width: "25rem", backgroundColor: '#FD7E14' }}>
                            <div class="card-body text-white">
                                <h4 class="card-title">Vor Ort</h4>
                                <hr />
                                <div className='d-flex '>
                                    <div>
                                        <img src={checklist_black} alt='..' className='w-100' style={{ width: '', height: 'auto' }} />
                                    </div>
                                    <div>
                                        <p className='ps-2'><span className='fw-bolder'>{tservice_recruite("cards_section").title1}</span> <br />{tservice_recruite("cards_section").content1}</p>
                                    </div>
                                </div>

                                <div className='d-flex '>
                                    <div>
                                        <img src={checklist_black} alt='..' className='w-100' style={{ width: '', height: 'auto' }} />
                                    </div>
                                    <div>
                                        <p className='ps-2'><span className='fw-bolder'>{tservice_recruite("cards_section").title2}</span> <br />{tservice_recruite("cards_section").content2}</p>                                    </div>
                                </div>

                                <div className='d-flex '>
                                    <div>
                                        <img src={checklist_black} alt='..' className='w-100' style={{ width: '', height: 'auto' }} />
                                    </div>
                                    <div>
                                        <p className='ps-2'><span className='fw-bolder'>{tservice_recruite("cards_section").title3}</span> <br />{tservice_recruite("cards_section").content3}</p>                                    </div>
                                </div>

                                <div className='d-flex '>
                                    <div>
                                        <img src={checklist_black} alt='..' className='w-100' style={{ width: '', height: 'auto' }} />
                                    </div>
                                    <div>
                                        <p className='ps-2'><span className='fw-bolder'>{tservice_recruite("cards_section").title4}</span> <br />{tservice_recruite("cards_section").content4}</p>                                    </div>
                                </div>

                                <div className='d-flex '>
                                    <div>
                                        <img src={checklist_black} alt='..' className='w-100' style={{ width: '', height: 'auto' }} />
                                    </div>
                                    <div>
                                        <p className='ps-2'><span className='fw-bolder'>{tservice_recruite("cards_section").title5}</span> <br />{tservice_recruite("cards_section").content5}</p>                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* client section */}
            <OurClients />

            {/* our experts */}
            <OurExperts />
        </>
    )
}
