import React from 'react'
import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css";
import "../assets/css/OurTeam.css"
import member1 from '../assets/img/member1.png'
import member2 from '../assets/img/member2.png'
import member3 from '../assets/img/member3.png'
import member4 from '../assets/img/member4.png'
import BlueDot from '../assets/img/blueDot.png'
import avatar from '../assets/img/avatar.png'
import OurClients from '../components/OurClients';
import OurExperts from '../components/OurExperts';



export default function OurTeam() {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <>
            {/* banner section */}
            <div className='container text-center' >
                <button className='btn main_text rounded-pill ps-4 pe-4 mb-3' style={{ backgroundColor: '#FFE6D2' }}>Our Team</button>
                <h1 className='fw-bolder'>Meet our highly talented team</h1>
                {/* <img src={recruiteBanner} className='w-50' /> */}
                <p>
                    Our team is a vibrant tapestry of backgrounds, expertise, and perspectives. We<br /> believe that diversity fuels innovation and creativity
                </p>
                <button className='btn btn-main_bg main_bg text-dark' >Book an appointment with our expert now</button>
            </div>

            {/* members section */}
            <div className='container py-5'>
                <div className='heading '>
                    <p className='text-uppercase m-0 main_text fw-bold'>LEADERSHIP</p>
                    <h2 className='fw-bold '>Our Company Leading Members</h2>
                    <p>Are you busy putting out IT fires instead of focusing on your core business !</p>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-3'>
                        <div class="card  mt-4">
                            <img src={member1} class="card-img-top w-100" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Brooklyn Simmons</h5>
                                <p class="card-text">CEO & Founder</p>
                                <hr className='mt-0 pt-0' style={{ border: '3px solid #FD7E14', width: '10%', opacity: 1 }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                        <div class="card  mt-4">
                            <img src={member2} class="card-img-top w-100" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Jenny Wilson</h5>
                                <p class="card-text">Co- Founder</p>
                                <hr className='mt-0 pt-0' style={{ border: '3px solid #FD7E14', width: '10%', opacity: 1 }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                        <div class="card  mt-4">
                            <img src={member3} class="card-img-top w-100" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Ronald Richards</h5>
                                <p class="card-text">Human Resources</p>
                                <hr className='mt-0 pt-0' style={{ border: '3px solid #FD7E14', width: '10%', opacity: 1 }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                        <div class="card  mt-4">
                            <img src={member4} class="card-img-top w-100" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Marvin McKinney</h5>
                                <p class="card-text">Business Development</p>
                                <hr className='mt-0 pt-0' style={{ border: '3px solid #FD7E14', width: '10%', opacity: 1 }} />
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-md-3'>
                        <div class="card  mt-4">
                            <img src={member1} class="card-img-top w-100" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Brooklyn Simmons</h5>
                                <p class="card-text">CEO & Founder</p>
                                <hr className='mt-0 pt-0' style={{ border: '3px solid #FD7E14', width: '10%', opacity: 1 }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                        <div class="card  mt-4">
                            <img src={member2} class="card-img-top w-100" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Jenny Wilson</h5>
                                <p class="card-text">Co- Founder</p>
                                <hr className='mt-0 pt-0' style={{ border: '3px solid #FD7E14', width: '10%', opacity: 1 }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                        <div class="card  mt-4">
                            <img src={member3} class="card-img-top w-100" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Ronald Richards</h5>
                                <p class="card-text">Human Resources</p>
                                <hr className='mt-0 pt-0' style={{ border: '3px solid #FD7E14', width: '10%', opacity: 1 }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                        <div class="card  mt-4">
                            <img src={member4} class="card-img-top w-100" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Marvin McKinney</h5>
                                <p class="card-text">Business Development</p>
                                <hr className='mt-0 pt-0' style={{ border: '3px solid #FD7E14', width: '10%', opacity: 1 }} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* boost our team section */}
            <div className='container-fluid' style={{ backgroundColor: '#1C1C1C' }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-4 d-flex align-items-center p-5 '>
                            <div className='heading  text-white'>
                                <p className='text-uppercase m-0 main_text fw-bold'>FEATURES</p>
                                <h2 className='fw-bold '>Boosting your
                                    business using
                                    our team</h2>
                                <p>Our team is a melting pot of diverse
                                    expertise and skills. From seasoned
                                    industry to young talents, member
                                    brings a unique set of experiences</p>
                            </div>
                        </div>
                        <div className='col-12 col-md p-5 '>
                            <div className='row text-white border-start ps-5'>
                                <div className='col-12 col-md-6 mb-4'>
                                    <h5><img src={BlueDot} alt='..' className='pe-2' />Diverse Expertise</h5>
                                    <p>Our team boasts a diverse range of the
                                        expertise, compassing various disciplines
                                        and industries. This wealth of knowledge
                                        allows us</p>
                                </div>
                                <div className='col-12 col-md-6 mb-4'>
                                    <h5><img src={BlueDot} alt='..' className='pe-2' />Collaborative Culture</h5>
                                    <p>Our team boasts a diverse range of the
                                        expertise, compassing various disciplines
                                        and industries. This wealth of knowledge
                                        allows us</p>
                                </div>
                                <div className='col-12 col-md-6 mb-4'>
                                    <h5><img src={BlueDot} alt='..' className='pe-2' />Customer-Centric Approach</h5>
                                    <p>Our team boasts a diverse range of the
                                        expertise, compassing various disciplines
                                        and industries. This wealth of knowledge
                                        allows us</p>
                                </div>
                                <div className='col-12 col-md-6 mb-4'>
                                    <h5><img src={BlueDot} alt='..' className='pe-2' />Passion for Excellence</h5>
                                    <p>Our team boasts a diverse range of the
                                        expertise, compassing various disciplines
                                        and industries. This wealth of knowledge
                                        allows us</p>
                                </div>
                                <div className='col-12 col-md-6 mb-4'>
                                    <h5><img src={BlueDot} alt='..' className='pe-2' />Empowered Learning</h5>
                                    <p>Our team boasts a diverse range of the
                                        expertise, compassing various disciplines
                                        and industries. This wealth of knowledge
                                        allows us</p>
                                </div>
                                <div className='col-12 col-md-6 mb-4'>
                                    <h5><img src={BlueDot} alt='..' className='pe-2' />Innovation and Creativity</h5>
                                    <p>Our team boasts a diverse range of the
                                        expertise, compassing various disciplines
                                        and industries. This wealth of knowledge
                                        allows us</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*  */}
            <div className='container py-5'>
                <div className='heading text-center'>
                    <p className='text-uppercase m-0 main_text fw-bold'>Testimonials</p>
                    <h2 className='fw-bold '>Don’t take our word for it</h2>
                </div>

                <Carousel className='container testimonial_carousal py-3' responsive={responsive} infinite={true}>
                    <div>

                        <h1><div class="card mx-auto shadow-sm" style={{ width: '90%' }}>
                            <div class="card-body">
                                <h5 class="card-title">“The best Webflow Templates”</h5>
                                {/* <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
                                <p class="card-text fs-6 fw-normal lh-lg">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure sint amet occaecat cupidatat non proident</p>
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <img src={avatar} alt='...' className='' />
                                    </div>
                                    <div className='ps-2'>
                                        <p className='fs-5 mb-0 fw-bold'>Sophia Moore</p>
                                        <p className='fs-6 mb-0'>CEO at Webflow Agency</p>
                                    </div>
                                </div>
                            </div>
                        </div></h1>
                    </div>

                    <div>

                        <h1><div class="card mx-auto shadow" style={{ width: '90%' }}>
                            <div class="card-body">
                                <h5 class="card-title">“The best Webflow Templates”</h5>
                                {/* <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
                                <p class="card-text fs-6 fw-normal lh-lg">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure sint amet occaecat cupidatat non proident</p>
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <img src={avatar} alt='...' className='' />
                                    </div>
                                    <div className='ps-2'>
                                        <p className='fs-5 mb-0 fw-bold'>Sophia Moore</p>
                                        <p className='fs-6 mb-0'>CEO at Webflow Agency</p>
                                    </div>
                                </div>
                            </div>
                        </div></h1>
                    </div>

                    <div>

                        <h1><div class="card mx-auto shadow" style={{ width: '90%' }}>
                            <div class="card-body">
                                <h5 class="card-title">“The best Webflow Templates”</h5>
                                {/* <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
                                <p class="card-text fs-6 fw-normal lh-lg">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure sint amet occaecat cupidatat non proident</p>
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <img src={avatar} alt='...' className='' />
                                    </div>
                                    <div className='ps-2'>
                                        <p className='fs-5 mb-0 fw-bold'>Sophia Moore</p>
                                        <p className='fs-6 mb-0'>CEO at Webflow Agency</p>
                                    </div>
                                </div>
                            </div>
                        </div></h1>
                    </div>

                    <div>

                        <h1><div class="card mx-auto shadow" style={{ width: '90%' }}>
                            <div class="card-body">
                                <h5 class="card-title">“The best Webflow Templates”</h5>
                                {/* <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
                                <p class="card-text fs-6 fw-normal lh-lg">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure sint amet occaecat cupidatat non proident</p>
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <img src={avatar} alt='...' className='' />
                                    </div>
                                    <div className='ps-2'>
                                        <p className='fs-5 mb-0 fw-bold'>Sophia Moore</p>
                                        <p className='fs-6 mb-0'>CEO at Webflow Agency</p>
                                    </div>
                                </div>
                            </div>
                        </div></h1>
                    </div>


                </Carousel>
            </div>

            {/* our clients */}
            <OurClients/>

            {/* our experts */}
            <OurExperts/>
        </>
    )
}
