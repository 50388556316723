import React from 'react'
import ServicePrototypeBanner from '../assets/img/Design_banner.png'
import MobileappDesign from '../assets/img/MobileappDesign.png'
import WebDesign from '../assets/img/WebDesign.png'
import BrandingDesign from '../assets/img/BrandingDesign.png'
import IllustrationDesign from '../assets/img/IllustrationDesign.png'
import AR3DDesign from '../assets/img/AR3DDesign.png'
import MotionGraphicDesign from '../assets/img/MotionGraphicDesign.png'
import OurClients from '../components/OurClients'
import OurExperts from '../components/OurExperts'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'


export default function Design() {
    const { t: tservice_design, i18n: i18nservice_design } = useTranslation('service_design');
    const { t: tcommon, i18n: i18ncommon } = useTranslation('common');


    return (
        <>
            {/* banner section */}
            <div className='container'>
                <div className='text-center'>
                    <button className='btn main_text rounded-pill ps-4 pe-4 text-uppercase' style={{ backgroundColor: '#FFE6D2' }}>{tservice_design("banner").sub_heading}</button>
                </div>
                <div className='row align-items-center' style={{ height: '80vh' }}>
                    <div className='col-12 py-4 col-md d-flex align-items-center justify-content-center'>
                        <div className='center'>

                            <h1>{tservice_design("banner").heading}</h1>
                            <p className='fs-5'>{tservice_design("banner").heading_content} </p>
                            <Link  to="/contact" style={{ backgroundColor: '#FD7E14' }} className='btn btn-md me-3 text-white rounded-1 p-2 pe-4 ps-4'>{tcommon("get_in_touch")} <FontAwesomeIcon icon={faArrowRightLong} className="ps-2" /></Link>
                        </div>
                    </div>
                    <div className='col-12 py-4 col-md d-flex align-items-center justify-content-center'>
                        <img src={ServicePrototypeBanner} className='' style={{ width: '100%', float: 'right' }} />
                    </div>
                </div>
            </div>

            {/* design work section */}
            <div className='container py-5 pb-0 text-center'>
                <h3 className='fw-bold pb-2'>{tservice_design("cards_section").heading}</h3>
                <p className=''>{tservice_design("cards_section").heading_content}</p>
                <div className='container py-5 w-75 mx-auto'>
                    <div className='row'>
                        <div className='col-12 col-md-4 mb-4'>
                            <img src={MobileappDesign} alt='...' className='w-100' />
                            <h4 className='fw-bold py-3'>{tservice_design("cards_section").design_mobiler_apps_title}</h4>
                        </div>
                        <div className='col-12 col-md-4 mb-4'>
                            <img src={WebDesign} alt='...' className='w-100' />
                            <h4 className='fw-bold py-3'>{tservice_design("cards_section").web_design_title}</h4>
                        </div>
                        <div className='col-12 col-md-4 mb-4'>
                            <img src={BrandingDesign} alt='...' className='w-100' />
                            <h4 className='fw-bold py-3'>{tservice_design("cards_section").branding_design_title}</h4>
                        </div>
                        <div className='col-12 col-md-4 mb-4'>
                            <img src={IllustrationDesign} alt='...' className='w-100' />
                            <h4 className='fw-bold py-3'>{tservice_design("cards_section").illustration_design_title}</h4>
                        </div>
                        <div className='col-12 col-md-4 mb-4'>
                            <img src={AR3DDesign} alt='...' className='w-100' />
                            <h4 className='fw-bold py-3'>{tservice_design("cards_section").ar3d_design_title}</h4>
                        </div>
                        <div className='col-12 col-md-4 mb-4'>
                            <img src={MotionGraphicDesign} alt='...' className='w-100' />
                            <h4 className='fw-bold py-3'>{tservice_design("cards_section").motion_graphic_title}</h4>
                        </div>
                    </div>
                </div>
            </div>

            {/* our clients */}
            <OurClients />

            {/* our experts */}
            <OurExperts />
        </>
    )
}
