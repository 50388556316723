import React from 'react'
import ServicePlatformBanner from '../assets/img/ServicePlatformBanner.png'
import PlatformService_android from '../assets/img/PlatformService_android.png'
import PlatformService_js from '../assets/img/PlatformService_js.png'
import PlatformService_flutter from '../assets/img/PlatformService_flutter.png'
import PlatformService_java from '../assets/img/PlatformService_java.png'
import PlatformService_rn from '../assets/img/PlatformService_rn.png'
import PlatformService_php from '../assets/img/PlatformService_php.png'
import PlatformService_adobe from '../assets/img/PlatformService_adobe.png'
import PlatformService_njs from '../assets/img/PlatformService_njs.png'
import PlatformService_angular from '../assets/img/PlatformService_angular.png'
import OurExperts from '../components/OurExperts'
import OurClients from '../components/OurClients'
import '../assets/css/ServicePlatform.css'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'



export default function ServicePlatform() {
    const { t: tservice_platform, i18n: i18nservice_platform } = useTranslation('service_platform');
    const { t: tcommon, i18n: i18ncommon } = useTranslation('common');


    return (
        <>
            {/* banner section */}
            <div className='container'>
                <div className='text-center'>
                    <button className='btn main_text rounded-pill ps-4 pe-4 text-uppercase' style={{ backgroundColor: '#FFE6D2' }}>{tservice_platform("banner").sub_heading}</button>
                </div>
                <div className='row align-items-center' style={{ height: '80vh' }}>
                    <div className='col-12 col-md py-5 d-flex align-items-center justify-content-center'>
                        <div className='center'>

                            <h1>{tservice_platform("banner").heading}</h1>
                            <p className='fs-5'> {tservice_platform("banner").heading_content}</p>
                            <Link  to="/contact" style={{ backgroundColor: '#FD7E14' }} className='btn btn-md me-3 text-white rounded-1 p-2 pe-4 ps-4'>{tcommon("get_in_touch")} <FontAwesomeIcon icon={faArrowRightLong} className="ps-2" /></Link>
                        </div>
                    </div>
                    <div className='col-12 col-md py-5 d-flex align-items-center justify-content-center'>
                        <img src={ServicePlatformBanner} className='' style={{ width: '100%', float: 'right' }} />
                    </div>
                </div>
            </div>

            {/* app platform section */}
            <div className='py-5 container'>
                <h3 className='text-center pb-5 fw-bold'>{tservice_platform("card_section").heading}</h3>

                <div className='row w-50 app_platform mx-auto'>
                    <div className='col-4 text-center'>
                        <div>
                            <img src={PlatformService_android} className='w-50' />
                            <p className='fw-bold'>Android</p>
                        </div>
                    </div>
                    <div className='col-4 text-center'>
                        <div>
                            <img src={PlatformService_js} className='w-50' />
                            <p className='fw-bold'>React JS</p>
                        </div>
                    </div>
                    <div className='col-4 text-center'>
                        <div>
                            <img src={PlatformService_flutter} className='w-50' />
                            <p className='fw-bold'>Flutter</p>
                        </div>
                    </div>
                    <div className='col-4 text-center'>
                        <div>
                            <img src={PlatformService_java} className='w-50' />
                            <p className='fw-bold'>Java</p>
                        </div>
                    </div>
                    <div className='col-4 text-center'>
                        <div>
                            <img src={PlatformService_rn} className='w-50' />
                            <p className='fw-bold'>React Native</p>
                        </div>
                    </div>
                    <div className='col-4 text-center'>
                        <div>
                            <img src={PlatformService_php} className='w-50' />
                            <p className='fw-bold'>PHP</p>
                        </div>
                    </div>
                    <div className='col-4 text-center'>
                        <div>
                            <img src={PlatformService_adobe} className='w-50' />
                            <p className='fw-bold'>Adope</p>
                        </div>
                    </div>
                    <div className='col-4 text-center'>
                        <div>
                            <img src={PlatformService_njs} className='w-50' />
                            <p className='fw-bold'>Node JS</p>
                        </div>
                    </div>
                    <div className='col-4 text-center'>
                        <div>
                            <img src={PlatformService_angular} className='w-50' />
                            <p className='fw-bold'>Angular</p>
                        </div>
                    </div>


                </div>
            </div>

            {/* client section */}
            <div className='py-5'>
                <OurClients />
            </div>

            {/* our experts */}
            <OurExperts />

        </>
    )
}
