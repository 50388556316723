import React from 'react'
import client1 from '../assets/img/client1.png'
import client2 from '../assets/img/client2.png'
import client3 from '../assets/img/client3.png'
import client4 from '../assets/img/client4.png'
import client5 from '../assets/img/client5.png'
import ourCompany_bg from '../assets/img/ourCompany_bg.png'
import our_services_bg from '../assets/img/our_services_bg.png'
import jenifer from '../assets/img/jenifer.png'
import OurExperts from '../components/OurExperts'
import OurClients from '../components/OurClients'
import '../assets/css/About.css'
import HonestyIcon from '../assets/img/honesty-icon.png'
import ChallengeIcon from '../assets/img/challenge-target-icon.png'
import profileIcon from '../assets/img/profileIcon.png'
import HeartCareIcon from '../assets/img/heart-care-icon.png'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'
import { Link } from 'react-router-dom'



export default function About() {
  const { t: tcommon, i18n: i18ncommon } = useTranslation('common');
  const { t: tabout, i18n: i18nabout } = useTranslation('about');

  return (
    <>
      {/* header and banner section */}
      <div className='container' style={{ height: '90vh' }}>
        <div className='text-center'>
          <button className='btn main_text rounded-pill ps-4 pe-4 mb-3 text-uppercase' style={{ backgroundColor: '#FFE6D2' }}>{tabout("banner").sub_text}</button>
        </div>
        <div>
          <div className='text-center mx-auto'>
            <h1 className='fw-bold'>{tabout("banner").heading}</h1>
            <p className='w-75 mx-auto'>{tabout("banner").heading_content}</p>
          </div>
          <div className='row py-4 '>
            <div className='col-md-3 col-12 pb-3'>
              <div className='p-5 rounded-4 shadow w-100' style={{ border: '2px solid orange', height: '100%' }}>
                <h1 className='main_text fw-bold'>220+</h1>
                <p className='fw-bold'>{tabout("cards_section").project_title}</p>
                <p>{tabout("cards_section").project_content}</p>
              </div>
            </div>
            <div className='col-md-3 col-12 pb-3'>
              <div className='p-5 rounded-4 shadow' style={{ border: '2px solid orange', height: '100%' }}>
                <h1 className='main_text fw-bold'>100%</h1>
                <p className='fw-bold'>{tabout("cards_section").client_satisfaction_title}</p>
                <p>{tabout("cards_section").client_satisfaction_content}</p>
              </div>
            </div>
            <div className='col-md-3 col-12 pb-3'>
              <div className='p-5 rounded-4 shadow' style={{ border: '2px solid orange', height: '100%' }}>
                <h1 className='main_text fw-bold'>1k</h1>
                <p className='fw-bold'>{tabout("cards_section").legal_customers_title}</p>
                <p>{tabout("cards_section").legal_customers_content}</p>
              </div>
            </div>
            <div className='col-md-3 col-12 pb-3'>
              <div className='p-5 rounded-4 shadow' style={{ border: '2px solid orange', height: '100%' }}>
                <h1 className='main_text fw-bold'>2024</h1>
                <p className='fw-bold'>{tabout("cards_section").we_established_title}</p>
                <p>{tabout("cards_section").we_established_content}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* client section */}
      <div className='py-5'>
        <OurClients />
      </div>

      {/* our company */}
      <div className='container my-5 rounded-3' style={{ backgroundColor: '#F4F4F4' }}>
        <div className='p-5 pb-0'>
          <div className='heading pb-5 d-flex justify-content-between align-items-center flex-wrap'>
            <div>
              <p className='text-uppercase m-0 main_text fw-bold'>OUR COMPANY</p>
              <h1 className=' fw-bold'>{tabout("our_company_section").heading}</h1>
            </div>
            <div>
              <Link to="/contact" className='btn main_bg rounded-pill ps-3 pe-3 text-white'>Contact Us</Link>
            </div>
          </div>

          <div>
            <img src={ourCompany_bg} alt='...' className='w-100' />
            <p className='p-5'>{tabout("our_company_section").heading_content}
              <span><hr /></span>

              <div className='d-flex justify-content-between flex-wrap'>
                <p><FontAwesomeIcon icon={faCircleCheck} className="main_text pe-2" />
                  Web Application</p>
                <p><FontAwesomeIcon icon={faCircleCheck} className="main_text pe-2" />Mobile Application</p>
                <p><FontAwesomeIcon icon={faCircleCheck} className="main_text pe-2" />Offshore Development</p>
              </div>
            </p>
          </div>
        </div>
      </div>

      {/* our service section */}
      <div className='container-fluid my-5' style={{ backgroundColor: '#1A202C' }}>
        <div className='container'>
          <div className='row text-white'>
            <div className='col-12 col-md'>
              <div className='p-5 ps-0'>
                <img src={our_services_bg} alt='...' className='w-100' />
              </div>
            </div>
            <div className='col-12 col-md'>
              <div className='p-5 pe-0'>
                <h1 className='pt-5'>{tabout("our_company_section").why_best_title}</h1>
                <p className='pt-4'>{tabout("our_company_section").why_best_content}</p>
                <div className='d-flex justify-content-start flex-wrap pt-4'>
                  <p className='pe-5'><FontAwesomeIcon icon={faCircleCheck} className="main_text pe-2" /> PPD Development</p>
                  <p><FontAwesomeIcon icon={faCircleCheck} className="main_text pe-2" />Quick Response</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* Our values section */}
      <div className='container py-5'>
        <div className='row py-5 pt-0 mx-auto '>
          <h1 className='text-center pb-4'>{tabout("our_services_section").heading}</h1>


          <div className='col-12'>
            <div className='c-parent our_values_circleContainer'>
              <div className='c-child'>
                <div className=' mx-auto my-4'>
                  <div className='text-center'>
                    <img src={HonestyIcon} className='py-3' style={{ width: '35px' }} />
                    <h3>{tabout("our_services_section").title1}</h3>
                  </div>
                  {tabout("our_services_section").content1}
                </div>
              </div>
              <div className='c-child'>
                <div className='mx-auto my-4'>
                  <div className='text-center'>
                    <img src={ChallengeIcon} className='py-3' style={{ width: '30px' }} />
                    <h3>{tabout("our_services_section").title2}</h3>
                  </div>
                  {tabout("our_services_section").content2}
                </div>
              </div>
              <div className='c-child'>
                <div className='mx-auto my-4'>
                  <div className='text-center'>
                    <img src={profileIcon} className='py-3' style={{ width: '35px' }} />
                    <h3>{tabout("our_services_section").title3}</h3>
                  </div>
                  {tabout("our_services_section").content3}
                </div>
              </div>
              <div className='c-child'>
                <div className=' mx-auto mb-5'>
                  <div className='text-center'>
                    <img src={HeartCareIcon} className='py-3' style={{ width: '35px' }} />
                    <h3>{tabout("our_services_section").title4}</h3>
                  </div>
                  {tabout("our_services_section").content4}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* client section */}
      <div className=''>
        <OurClients />
      </div>

      {/* our experts section*/}
      <OurExperts />
    </>
  )
}
