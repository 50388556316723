import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";  
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng:"de",
    fallbackLng: "de",
    debug: true,
    returnObjects:true,
  
    ns: ['translations'],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    },
    backend: {
      // loadPath: () => {
      //   // check the domain
      //   const host = window.location.host;
      //   return (host === 'production.ltd' ? '/static/app':'') + '/zogi_nature/locales/{{lng}}/{{ns}}.json';
      // }

      loadPath:'/locales/{{lng}}/{{ns}}.json'
    }
  });

export default i18n;

