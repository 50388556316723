import React from 'react';
import EcommerceBanner from '../assets/img/EcommerceBanner.png'
import WooCommerce from '../assets/img/WooCommerce.png'
import Shopify from '../assets/img/Shopify.png'
import OpenCart from '../assets/img/OpenCart.png'
import OurClients from '../components/OurClients';
import OurExperts from '../components/OurExperts';
import MobilappPortfolio_lkn from '../assets/img/MobilappPortfolio_lkn.png'
import MobilappPortfolio_bee from '../assets/img/MobilappPortfolio_bee.png'
import MobilappPortfolio_accopedia from '../assets/img/MobilappPortfolio_accopedia.png'
import MobilappPortfolio_class from '../assets/img/MobilappPortfolio_class.png'
import uparrow from '../assets/img/arrow-right-up.png'
import uparrowWhite from '../assets/img/arrow-right-up_white.png'
import { useTranslation } from 'react-i18next';
import Shadow from '../assets/img/shadow.png';
import shadowRight from '../assets/img/shadowRight.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';



export default function ServiceECommerce() {

    const { t: tservice_ecommerce, i18n: i18nservice_ecommerce } = useTranslation('service_ecommerce');
    const { t: tcommon, i18n: i18ncommon } = useTranslation('common');

    return (
        <>
            {/* banner section */}
            <div className='container'>
                <div className='text-center'>
                    <button className='btn main_text rounded-pill ps-4 pe-4 text-uppercase' style={{ backgroundColor: '#FFE6D2' }}>{tservice_ecommerce("banner").sub_heading}</button>
                </div>
                <div className='row align-items-center' style={{ height: '80vh' }}>
                    <div className='col-12 py-4 col-md d-flex align-items-center justify-content-center'>
                        <div className='center'>
                            <h1>{tservice_ecommerce("banner").heading}</h1>
                            <p className='fs-5'> {tservice_ecommerce("banner").heading_content}</p>
                            <Link to="/contact" style={{ backgroundColor: '#FD7E14' }} className='btn btn-md me-3 text-white rounded-1 p-2 pe-4 ps-4'>{tcommon("get_in_touch")} <FontAwesomeIcon icon={faArrowRightLong} className="ps-2" /></Link>
                        </div>
                    </div>
                    <div className='col-12 py-4 col-md d-flex align-items-center justify-content-center'>
                        <img src={EcommerceBanner} className='' style={{ width: '90%', float: 'right' }} />
                    </div>
                </div>
            </div>

            <div className='container py-5 pb-0 text-center'>
                <h3 className='fw-bold pb-2'>{tservice_ecommerce("cards_section").heading}</h3>
                <p className='w-75 mx-auto'>{tservice_ecommerce("cards_section").heading_content}</p>


                <div className='container py-5 pt-2'>
                    <div className='row'>
                        <div className='col'>
                            <div className='border border-2 mb-3 rounded-3' style={{ height: '100%' }}>
                                <div class="card p-4 pt-5 pb-4 border-0 mt-2">
                                    <img class=" mx-auto" style={{ width: '25%' }} src={WooCommerce} alt="Card image cap" />
                                    <div class="card-body text-center">
                                        <h4 class="card-title fw-bolder main_text pb-2">{tservice_ecommerce("cards_section").woocommerce_title}</h4>
                                        <p class="text-dark ">{tservice_ecommerce("cards_section").woocommerce_content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='border border-2 mb-3 rounded-3' style={{ height: '100%' }}>
                                <div class="card p-4 pt-5 pb-4 border-0 mt-2">
                                    <img class=" mx-auto" style={{ width: '15%' }} src={Shopify} alt="Card image cap" />
                                    <div class="card-body text-center">
                                        <h4 class="card-title fw-bolder main_text pb-2">{tservice_ecommerce("cards_section").shopify_title}</h4>
                                        <p class="text-dark">{tservice_ecommerce("cards_section").shopify_content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='border border-2 mb-3 rounded-3' style={{ height: '100%' }}>
                                <div class="card p-4 pt-4 pb-4 border-0 mt-2">
                                    <img class=" mx-auto mt-3" style={{ width: '20%' }} src={OpenCart} alt="Card image cap" />
                                    <div class="card-body text-center">
                                        <h4 class="card-title fw-bolder main_text pb-2">{tservice_ecommerce("cards_section").opencart_title}</h4>
                                        <p class="text-dark">{tservice_ecommerce("cards_section").opencart_content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* our clients */}
            <OurClients />

            {/* portfolio section */}
            <div className='container py-5'>
                <h1 className='text-center fw-bold pb-4'>{tservice_ecommerce("portfolio_section").heading}</h1>
                <div className='row'>
                    <div className='col'>
                        <div class="card border-0 mb-3" style={{ height: '100%' }}>
                            <img src={MobilappPortfolio_lkn} class="card-img-top" alt="..." />
                            <div class="card-body  mt-4 rounded-3 p-4" style={{ backgroundColor: '#292929', color: 'white' }}>
                                <h5 class="card-title">Lakshmi krishna <span><img src={uparrowWhite} /></span></h5>
                                <p class="card-text">{tservice_ecommerce("portfolio_section").lnk_content}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div class="card border-0 mb-3" style={{ height: '100%' }}>
                            <img src={MobilappPortfolio_bee} class="card-img-top" alt="..." />
                            <div class="card-body  mt-4 rounded-3 p-4" style={{ backgroundColor: '#CECDCB' }}>
                                <h5 class="card-title">Bee Group <span><img src={uparrow} /></span></h5>
                                <p class="card-text">{tservice_ecommerce("portfolio_section").bee_content}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div class="card border-0 mb-3" style={{ height: '100%' }}>
                            <img src={MobilappPortfolio_accopedia} class="card-img-top" alt="..." />
                            <div class="card-body  mt-4 rounded-3 p-4" style={{ backgroundColor: '#F4F4F4' }}>
                                <h5 class="card-title">Accopedia <span><img src={uparrow} /></span></h5>
                                <p class="card-text">{tservice_ecommerce("portfolio_section").accopedia_content}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div class="card border-0 mb-3" style={{ height: '100%' }}>
                            <img src={MobilappPortfolio_class} class="card-img-top" alt="..." />
                            <div class="card-body  mt-4 rounded-3 p-4" style={{ backgroundColor: '#292929', color: 'white' }}>
                                <h5 class="card-title">Class Tales <span><img src={uparrowWhite} /></span></h5>
                                <p class="card-text">{tservice_ecommerce("portfolio_section").clastale_content}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* FAQ Section */}
            <div className='container-fluid py-5 position-relative mb-5 '>


                <h3 className='text-center pb-3'>Frequently Asked Questions</h3>
                <div class="accordion  mx-auto faq" id="accordionExample" style={{ width: '75%' }}>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                {tservice_ecommerce("faq_section").question1}
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_ecommerce("faq_section").answer1}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                {tservice_ecommerce("faq_section").question2}
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_ecommerce("faq_section").answer2}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                {tservice_ecommerce("faq_section").question3}
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_ecommerce("faq_section").answer3}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                {tservice_ecommerce("faq_section").question4}
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_ecommerce("faq_section").answer4}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                {tservice_ecommerce("faq_section").question5}
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_ecommerce("faq_section").answer5}
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                {tservice_ecommerce("faq_section").question6}
                            </button>
                        </h2>
                        <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {tservice_ecommerce("faq_section").answer6}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='position-absolute' style={{ right: 0, bottom: -250 }}>
                    <img src={shadowRight} width="100%" style={{ position: 'relative', zIndex: -1 }} />
                </div>

            </div>

            {/* our expert */}
            <OurExperts />
        </>
    )
}
