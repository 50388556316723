import React from 'react'
import '../assets/css/TimeLine.css'
import SCHRITT1 from '../assets/img/SCHRITT1.png'
import SCHRITT2 from '../assets/img/SCHRITT2.png'
import SCHRITT3 from '../assets/img/SCHRITT3.png'
import SCHRITT4 from '../assets/img/SCHRITT4.png'
import SCHRITT5 from '../assets/img/SCHRITT5.png'
import { useTranslation } from 'react-i18next'

export default function ProcessTimeline() {
    const { t: tprocess, i18n: i18nprocess } = useTranslation('process');

    return (
        <>
            <div class="container pt-5">
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-timeline">
                            <div class="timeline">
                                <a href="#" class="timeline-content">
                                    <div className='row'>
                                        <div className='col d-flex align-items-center'>
                                            <img class="timeline-year" src={SCHRITT5} alt='...' className='w-50 ps-5 ms-5 mt-0' />
                                        </div>
                                        <div className='col'>
                                            <div class="content">
                                                <h3 class="title">{tprocess("SCHRITT1_section").title}</h3>
                                                <p class="fs-5 description">
                                                    {tprocess("SCHRITT1_section").content}
                                                    <ul className='fs-5 '>
                                                        <li>{tprocess("SCHRITT1_section").list_item1}</li>
                                                        <li>{tprocess("SCHRITT1_section").list_item2}</li>
                                                        <li>{tprocess("SCHRITT1_section").list_item3}</li>
                                                        <li>{tprocess("SCHRITT1_section").list_item4}</li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="timeline">
                                <a href="#" class="timeline-content">
                                    <div className='row'>
                                        <div className='col d-flex align-items-center'>
                                            <div class="content">
                                                <h3 class="title">{tprocess("SCHRITT2_section").title}</h3>
                                                <p class="fs-5 description">
                                                    {tprocess("SCHRITT2_section").content}

                                                    <ul className='fs-5 '>
                                                        <li>{tprocess("SCHRITT1_section").list_item1}</li>
                                                        <li>{tprocess("SCHRITT2_section").list_item2}</li>
                                                        <li>{tprocess("SCHRITT3_section").list_item3}</li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col d-flex align-items-center'>
                                            <img class="timeline-year" src={SCHRITT2} alt='...' className='w-50 ps-5 ms-5 mt-0' />
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="timeline">
                                <a href="#" class="timeline-content">
                                    <div className='row'>
                                        <div className='col d-flex align-items-center'>
                                            <img class="timeline-year" src={SCHRITT3} alt='...' className='w-50 ps-5 ms-5 mt-0' />
                                        </div>
                                        <div className='col'>
                                            <div class="content">
                                                <h3 class="title">{tprocess("SCHRITT3_section").title}
                                                </h3>
                                                <ul className='fs-5'>
                                                    <li>{tprocess("SCHRITT3_section").list_item1}</li>
                                                    <li>{tprocess("SCHRITT3_section").list_item2}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </a>
                            </div>
                            <div class="timeline">
                                <a href="#" class="timeline-content">
                                    <div className='row'>
                                        <div className='col d-flex align-items-center'>
                                            <div class="content">
                                                <h3 class="title">{tprocess("SCHRITT4_section").title}</h3>
                                                <ul className='fs-5'>
                                                    <li>{tprocess("SCHRITT4_section").list_item1}</li>
                                                    <li>{tprocess("SCHRITT4_section").list_item2}</li>
                                                    <li>
                                                    {tprocess("SCHRITT4_section").list_item3}
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                        <div className='col d-flex align-items-center'>
                                            <img class="timeline-year" src={SCHRITT2} alt='...' className='w-50 ps-5 ms-5 mt-0' />
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="timeline">
                                <a href="#" class="timeline-content">
                                    <div className='row'>
                                        <div className='col d-flex align-items-center'>
                                            <img class="timeline-year" src={SCHRITT5} alt='...' className='w-50 ps-5 ms-5 mt-0' />
                                        </div>
                                        <div className='col'>
                                            <div class="content">
                                                <h3 class="title">{tprocess("SCHRITT5_section").title}</h3>
                                                <ul className='fs-5'>
                                                    <li>{tprocess("SCHRITT5_section").list_item1}</li>
                                                    <li>{tprocess("SCHRITT5_section").list_item2}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            {/* <div class="timeline">
                                <a href="#" class="timeline-content">
                                    <span class="timeline-year">2017</span>
                                    <div class="timeline-icon">
                                        <i class="fa fa-users"></i>
                                    </div>
                                    <div class="content">
                                        <h3 class="title">Java Script</h3>
                                        <p class="description">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias animi dolor in, maiores natus ipsum dolor sit amet, consectetur adipisicing elit. Alias animi dolor in, maiores natus.
                                        </p>
                                    </div>
                                </a>
                            </div>

                            <div class="timeline">
                                <a href="#" class="timeline-content">
                                    <span class="timeline-year">2017</span>
                                    <div class="timeline-icon">
                                        <i class="fa fa-users"></i>
                                    </div>
                                    <div class="content">
                                        <h3 class="title">Java Script</h3>
                                        <p class="description">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias animi dolor in, maiores natus ipsum dolor sit amet, consectetur adipisicing elit. Alias animi dolor in, maiores natus.
                                        </p>
                                    </div>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
