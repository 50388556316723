import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../assets/img/logo.png'
import '../assets/css/Nav.css'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownLong, faArrowRightLong, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';

export default function Nav() {

    const location = useLocation();

    const isActive = (path) => {
        const navbarCollapse = document.querySelector('.navbar-collapse');
        if (navbarCollapse) {
            navbarCollapse.classList.remove('show');
        }
        // console.log(path);
        // alert(path)
        return location.pathname === path ? 'active' : '';

    };
    const { t, i18n } = useTranslation('header');
    const [lang, setLang] = useState(i18n.language === 'de');


    const changeLanguageHandler = lng => {
        i18n.changeLanguage(lng)
        setLang(lng === "de");
    };


    return (
        <>
            <nav className='navbar navbar-expand-lg  mt-0 mb-5 sticky-top' style={{ height: '78px', marginTop: '-78px', backgroundColor: '#313131', zIndex: 1001 }}>
                <div class="container">
                    <Link to="/" class=" text-white" href="#" style={{ width: '10%' }}><img src={logo} className='w-100 logo' /></Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
                            <li className={`nav-item ${(isActive('/')) ? 'active' : ''}`}>
                                <Link to="/" class="nav-link nav-menu text-white fw-bold active text-uppercase pe-3 ps-3" aria-current="page" style={{ fontSize: '13px' }}>{t("menu").home}</Link>
                            </li>
                            <li className={`nav-item dropdown ${(isActive('/app_development') || isActive('/prototype_development') || isActive('/platform_development') || isActive('/design') || isActive('/eCommerce_shop_development') || isActive('/testing_and_services') || isActive('/temporary_employment') || isActive('/recruitment') || isActive('/zoho_implementierung')) ? 'active' : ''}`}>
                                <li class="nav-link nav-menu fw-bold  text-white text-uppercase pe-3 ps-3 mb-0 dropdown-toggle" style={{ fontSize: '13px', cursor: 'pointer' }}>{t("menu").services}</li>
                                <ul className="dropdown-menu p-3">
                                    <li><Link to="/app_development" className={`dropdown-item border-bottom  p-2 ${(isActive('/app_development')) ? 'active' : ''}`}>{t('services_menu').app_development}
                                    </Link></li>
                                    <li><Link to="/prototype_development" className={`dropdown-item border-bottom  p-2 ${(isActive('/prototype_development')) ? 'active' : ''}`}>{t('services_menu').prototype_development}</Link></li>
                                    <li><Link to="/platform_development" className={`dropdown-item border-bottom  p-2 ${(isActive('/platform_development')) ? 'active' : ''}`}>{t('services_menu').platform_development}</Link></li>
                                    <li><Link to="/design" className={`dropdown-item border-bottom  p-2 ${(isActive('/design')) ? 'active' : ''}`}>{t('services_menu').design}</Link></li>
                                    <li><Link to="/eCommerce_shop_development" className={`dropdown-item border-bottom  p-2 ${(isActive('/eCommerce_shop_development')) ? 'active' : ''}`}>{t('services_menu').ecommerce_store_development}</Link></li>
                                    <li><Link to="/testing_and_services" className={`dropdown-item border-bottom  p-2 ${(isActive('/testing_and_services')) ? 'active' : ''}`}>{t('services_menu').qa_testing_and_services}</Link></li>
                                    <li><Link to="/temporary_employment" className={`dropdown-item border-bottom  p-2 ${(isActive('/temporary_employment')) ? 'active' : ''}`}>{t('services_menu').temporary_employment}</Link></li>
                                    <li><Link to="/recruitment" className={`dropdown-item  p-2 ${(isActive('/recruitment')) ? 'active' : ''}`}>{t('services_menu').recruitment}</Link></li>
                                    <li><Link to="/zoho_implementierung" className={`dropdown-item  p-2 ${(isActive('/zoho_implementierung')) ? 'active' : ''}`}>{t('services_menu').zoho_implementation}</Link></li>
                                </ul>
                            </li>

                            <li className={`nav-item ${(isActive('/process')) ? 'active' : ''}`}>
                                <Link to="/process" class="nav-link text-white nav-menu fw-bold text-uppercase pe-3 ps-3" aria-disabled="true" style={{ fontSize: '13px' }}>{t("menu").process}</Link>
                            </li>

                            <li className={`nav-item ${(isActive('/portfolio')) ? 'active' : ''}`}>
                                <Link to="/portfolio" class="nav-link text-white nav-menu fw-bold text-uppercase pe-3 ps-3" aria-disabled="true" style={{ fontSize: '13px' }}>{t("menu").references}</Link>
                            </li>


                            <li className={`nav-item dropdown ${(isActive('/about') || isActive('/our_team') || isActive('/career')) ? 'active' : ''}`}>
                                <li class="nav-link text-white nav-menu fw-bold text-uppercase pe-3 ps-3  mb-0 dropdown-toggle" style={{ fontSize: '13px', cursor: 'pointer' }}>{t("menu").about_us}</li>

                                <ul className="dropdown-menu p-3">
                                    <li><Link to="/about" className={`dropdown-item border-bottom  p-2 ${(isActive('/about')) ? 'active' : ''}`}>{t('about_menu').company}</Link></li>
                                    {/* <li><Link to="/our_team" className={`dropdown-item border-bottom  p-2 ${(isActive('/our_team')) ? 'active' : ''}`}>{t('about_menu').team}</Link></li>
                                    <li><Link to="/career" className={`dropdown-item   p-2 ${(isActive('/career')) ? 'active' : ''}`}>{t('about_menu').career}</Link></li> */}
                                </ul>
                            </li>

                            <li className={`nav-item dropdown ${(isActive('/contact')) ? 'active' : ''}`}>
                                <Link to="/contact" class="nav-link text-white nav-menu fw-bold text-uppercase pe-3 ps-3" aria-disabled="true" style={{ fontSize: '13px' }}>{t("menu").contact}</Link>
                            </li>
                        </ul>
                        <button className={`btn btn-outline dropdown-toggle nav-link language_switch fs-6 ms-1 rounded-3 pe-4 text-white`} aria-disabled="true" style={{ color: lang ? 'black' : 'black' }} onClick={() => changeLanguageHandler(lang ? "en" : "de")}><span className=''>{lang ? 'EN' : 'DE'}</span></button>


                        <form class="" role="search">
                            <p className='text-white m-0'><i class="fa-solid fa-phone-volume fs-6 align-self-center " />

                                <span className="d-inline-block align-middle pe-2">
                                    <FontAwesomeIcon icon={faPhoneVolume} />
                                </span>
                                <span className="align-middle">+49 72323090134</span>
                            </p>
                        </form>
                    </div>
                </div>
            </nav>
        </>
    )
}
